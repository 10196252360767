import React from "react";
import { IonIcon } from "@ionic/react";

interface FeatureCardProps {
  icon: string;
  title: string;
  description: string | string[];
}

const FeatureCard: React.FC<FeatureCardProps> = ({
  icon,
  title,
  description,
}) => (
  <div className="border py-6 border-gray-200 rounded-lg md:p-6 text-center shadow-sm min-h-[260px] md:min-h-[320px] flex flex-col justify-between items-center px-2">
    <div>
      <div className="flex justify-center mb-4">
        <IonIcon icon={icon} className="text-[63px] text-IconBlue" />
      </div>
      <h2 className="text-lg font-medium text-gray-900 mb-2">{title}</h2>
      <div className="text-gray-600 text-left">
        {Array.isArray(description) ? (
          description.map((line, index) => <p key={index}>{line}</p>)
        ) : (
          <p>{description}</p>
        )}
      </div>
    </div>
  </div>
);

export default FeatureCard;
