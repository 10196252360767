import React, { useEffect } from "react";

import { IonHeader, IonPage, IonTitle, IonToolbar } from "@ionic/react";

import { LoadPracticeSessions } from "../papiclient/client";
import { PracticeSessionListComponent } from "../components/PracticeSession/List";
import {
  PapiPracticeSessionsGETResponse,
  PracticeSessionListInfoItem,
} from "../models/PracticeSession";
import { setDarkMode } from "../data/user/user.actions";
import { connect } from "../data/connect";


interface StateProps {
  darkMode: boolean;
}

interface DispatchProps {
  setDarkMode: typeof setDarkMode;
}
interface PracticeSessionListPage extends StateProps, DispatchProps {}

const PracticeSessionListPage: React.FC<PracticeSessionListPage> = ({darkMode}) => {

  const [psList, setPsList] = React.useState<
    PracticeSessionListInfoItem[] | undefined
  >();

  useEffect(() => {
    LoadPracticeSessions()
      .then((pss: PracticeSessionListInfoItem[]) => setPsList(pss))
      .catch((reason) => console.log(reason));
  }, []);

  return (
    <IonPage id="practice-session-page">
      {psList === undefined ? (
        <IonHeader translucent={true}>
          <IonToolbar>
            <IonTitle>Loading ...</IonTitle>
          </IonToolbar>
        </IonHeader>
      ) : (
        <PracticeSessionListComponent darkMode={darkMode} psList={psList} />
      )}
    </IonPage>
  );
};

export default connect<PracticeSessionListPage, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    darkMode: state.user.darkMode,
  }),
  mapDispatchToProps: {
    setDarkMode,
  },
  component: PracticeSessionListPage,
});
