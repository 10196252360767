import React, { useEffect } from "react";

import {
  IonToolbar,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonText,
} from "@ionic/react";

import {
  PapiError,
  PracticeSession,
  WordQuestionType,
} from "../../models/PracticeSession";

import { DictionaryWordAnswerOptions } from "./DictionaryWordAnswerOptions";
import { StatusCodes } from "http-status-codes";

import "./PracticeSession.css";
import swal from "sweetalert";
import {
  AnswerSubmissionRequestStatus,
  NextPracticeRequestStatus,
} from "./QuestionCard";
import NextButton from "./NextButton";
import SubmitButton from "./SubmitButton";

export interface DictionaryWordCardProps {
  pSession: PracticeSession;
  setPracticeSession: (ps: PracticeSession) => void;
}

export const DictionaryWordCard: React.FC<DictionaryWordCardProps> = ({
  pSession,
  setPracticeSession,
}) => {
  const [
    answerSubmissionReqStatus,
    setAnswerSubmissionReqStatus,
  ] = React.useState<AnswerSubmissionRequestStatus>({
    submitting: false,
    error: null,
  });
  const [
    nextPracticeReqStatus,
    setNextPracticeReqReqStatus,
  ] = React.useState<NextPracticeRequestStatus>({
    submitting: false,
    error: null,
  });
  const practice = pSession.getCurrentPractice();

  const endPractice = () => {
    if (pSession?.id) {
      fetch(`/papi/institution/practice-session/${pSession.id}/stop`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((resp: Response) => {
          if (resp.status !== StatusCodes.OK) {
            return Promise.reject({
              code: resp.status,
              message: `failed to end the practice-session: ${resp.status} / ${resp.statusText}`,
            } as PapiError);
          }

          window.location.assign(`/PracticeSession/Summary/${pSession.id}`);
        })
        .catch((err: PapiError) => {
          console.error(err);
        });
    }
  };

  // when both the answer request and the next practice request has been
  // submitted then reset both the buttons
  if (
    nextPracticeReqStatus.error !== null &&
    // if we have no more practices pending then at-least allow resubmitting
    nextPracticeReqStatus.error.code === StatusCodes.NO_CONTENT
  ) {
    swal({
      title: "Not enough practices!",
      text:
        "This practice session does not have enough dictionary-words which meet all the criteria. Please let your instructors know so that they can add more dictionary-words to their wordlist.",
      icon: "warning",
      buttons: {
        cancel: {
          text: "No, I would like to come back later...",
          value: null,
          visible: true,
          className: "cancel-btn",
          closeModal: true,
        },
        confirm: {
          text: "Yes, End Now!",
          value: true,
          visible: true,
          className: "confirm-btn",
          closeModal: true,
        },
      },
    }).then((result) => {
      setNextPracticeReqReqStatus({
        submitting: false,
        error: null,
      });
      setAnswerSubmissionReqStatus({
        submitting: false,
        error: null,
      });
      if (result) {
        endPractice();
      } else {
        console.error("Cancelled");
      }
    });
  }

  if (practice.practiceableType !== "App\\Models\\DictionaryWord") {
    throw new Error("practice-type not supported: " + JSON.stringify(practice));
  }

  let title;
  switch (practice.wordQuestion.pattern) {
    case WordQuestionType.Prompt:
      title = `Do you know the meaning of word "${practice.wordQuestion.word.name}" ?`;
      break;
    case WordQuestionType.MultiMeaning:
      title = `Find the correct meaning of word "${practice.wordQuestion.word.name}" from the underlying options:`;
      break;
    case WordQuestionType.ReverseMeaning:
      title =
        "Can you guess the word that aligns best with the description below ?";
      break;
  }

  return (
    <IonCard>
      <IonCardHeader>
        <IonCardTitle>{title}</IonCardTitle>
      </IonCardHeader>
      <IonCardContent>
        <div
          id="wordQuestionType"
          data-word-question-type={practice.wordQuestion.pattern}
        ></div>
        {practice.wordQuestion.pattern === WordQuestionType.ReverseMeaning ? (
          <>
            <p>{practice.wordQuestion.word.meaning}</p>
            <br />
          </>
        ) : null}
        <br />
        <DictionaryWordAnswerOptions
          key={pSession.getCurrentPracticeID()}
          pSession={pSession}
          setPracticeSession={setPracticeSession}
        />
      </IonCardContent>

      <IonToolbar>
        {practice.answerSubmitted &&
        pSession.hasMorePractices() &&
        pSession.atLastKnownPractice() ? (
          <NextButton
            pSession={pSession}
            requestStatus={nextPracticeReqStatus}
            setRequestStatus={setNextPracticeReqReqStatus}
            setPracticeSession={setPracticeSession}
          />
        ) : null}

        <SubmitButton
          pSession={pSession}
          requestStatus={answerSubmissionReqStatus}
          setRequestStatus={setAnswerSubmissionReqStatus}
          setPracticeSession={setPracticeSession}
        />
      </IonToolbar>
      {answerSubmissionReqStatus.error !== null && (
        <IonText>{answerSubmissionReqStatus.error.message}</IonText>
      )}
    </IonCard>
  );
};
