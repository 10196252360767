// ImageCard.tsx
import React from "react";
import {
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  IonImg,
} from "@ionic/react";

interface ImageCardProps {
  src: string;
  title?: string;
  content?: string;
}

const ImageCard: React.FC<ImageCardProps> = ({ src, title, content }) => (
  <IonCard className="rounded-xl">
    <IonImg src={src} />
  </IonCard>
);

export default ImageCard;
