import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import HeroSection from "../components/Home/HeroSection/HeroSection";
import EducationalFeaturesSection from "../components/Home/EducationalFeaturesSection/EducationalFeaturesSection";
import CoolFeaturesSection from "../components/Home/FeatureSection/CoolFeaturesSection";
import ContactUs from "../components/Home/ContactUs/ContactUs";
import Footer from "../components/Home/Footer/Footer";
import Container from "../components/Container/Container";

const Home: React.FC = () => {
  const location = useLocation();
  const [institutionName, setInstitutionName] = useState("");

  useEffect(() => {
    const fetchTitleAndName = async () => {
      try {
        const hostname = window.location.hostname;
        const response = await fetch(`/papi/home/branding-title/${hostname}`);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const result = await response.json();
        document.title = result.title.replace(/^"|"$/g, ""); // Remove surrounding quotes if present
        setInstitutionName(result.name);
      } catch (error) {
        console.error("Failed to fetch the title and name:", error);
      }
    };

    fetchTitleAndName();
  }, [location]);

  return (
    <div>
      <div className="filter-gradient blue">
        <HeroSection institutionName={institutionName} />
      </div>

      <Container>
        <EducationalFeaturesSection />
        <CoolFeaturesSection />
        <ContactUs />
        <Footer />
      </Container>
    </div>
  );
};

export default Home;
