import React from "react";

interface SearchBarProps {
  onSearch: (e: React.FormEvent) => void;
  searchQuery: string;
  setSearchQuery: (query: string) => void;
  placeholder?: string;
  inputClassName?: string;
  buttonClassName?: string;
}

const SearchBar: React.FC<SearchBarProps> = ({
  onSearch,
  searchQuery,
  setSearchQuery,
  placeholder,
  inputClassName = "",
  buttonClassName = "",
}) => {
  return (
    <form
      onSubmit={onSearch}
      className="flex items-center w-full max-w-2xl p-[20px] bg-white rounded-sm"
    >
      <input
        type="text"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        placeholder={placeholder}
        id="txtSearchQuery"
        name="txtSearchQuery"
        className={`p-1.5 flex-grow text-[12px] bg-[#fafafa] border border-gray-300 rounded-l-md focus:bg-white focus:border-gray-300 focus:ring-0 focus:outline-none  ${inputClassName}`}
      />
      <button
        type="submit"
        name="search"
        className={`p-1 bg-[#3960d1] text-white px-4 hover:bg-blue-600 disabled:opacity-50 disabled:cursor-not-allowed ${buttonClassName}`}
        disabled={searchQuery.length === 0}
      >
        <i className="fa fa-search" aria-hidden="true"></i>
      </button>
    </form>
  );
};

export default SearchBar;
