import React from 'react';
import { IonGrid, IonRow, IonCol, IonText, IonIcon } from '@ionic/react';
import { homeOutline, businessOutline } from 'ionicons/icons';

const Footer: React.FC = () => {
  return (
    <footer className="py-1 bg-gray-100 px-8"> {/* Added horizontal padding here */}
      <IonGrid>
        {/* For Large and Medium Devices: Icons and Text in One Line */}
        <IonRow className="justify-between items-center hidden sm:flex">
          {/* Home and Company Links */}
          <IonCol className="flex justify-center md:justify-start">
            <div className="flex space-x-8">
              <a href="/" className="flex items-center text-gray-600 hover:text-IconBlue">
                <IonIcon icon={homeOutline} className="text-3xl text-IconBlue transition-colors duration-500 hover:text-[rgb(0,187,255,0.7)]" />
                <span className="ml-2">Home</span>
              </a>
              <a href="https://www.zerra.in/" className="flex items-center text-gray-600 hover:text-IconBlue">
                <IonIcon icon={businessOutline} className="text-3xl text-IconBlue transition-colors duration-500 hover:text-[rgb(0,187,255,0.7)]" />
                <span className="ml-2">Company</span>
              </a>
            </div>
          </IonCol>

          {/* Copyright Info */}
          <IonCol className="flex justify-center md:justify-end">
            <IonText className="flex items-center text-gray-600">
              <span className="text-2xl text-IconBlue transition-colors duration-500 hover:text-[rgb(0,187,255,0.7)]">©️</span>
              <span className="ml-2">
                Copyright © 2019{" "}
                <a href="https://www.zerra.in/" className="text-[#2ca8ff] hover:underline">
                  Zest Softech Pvt. Ltd.
                </a>
              </span>
            </IonText>
          </IonCol>
        </IonRow>

        {/* For Small Devices: Icons Aligned Consistently */}
        <IonRow className="flex items-center justify-between sm:hidden">
          <IonCol className="flex justify-start">
            <a href="/">
              <IonIcon icon={homeOutline} className="text-2xl text-IconBlue transition-colors duration-500 hover:text-[rgb(0,187,255,0.7)]" />
            </a>
          </IonCol>
          <IonCol className="flex justify-start">
            <a href="https://www.zerra.in/">
              <IonIcon icon={businessOutline} className="text-2xl text-IconBlue transition-colors duration-500 hover:text-[rgb(0,187,255,0.7)]" />
            </a>
          </IonCol>
          <IonCol className="flex justify-end">
            <span className="text-2xl text-IconBlue transition-colors duration-500 hover:text-[rgb(0,187,255,0.7)]">©️</span>
          </IonCol>
        </IonRow>
      </IonGrid>
    </footer>
  );
};

export default Footer;
