import { Preferences } from "@capacitor/preferences";
import { PracticeSession, Session } from "../models/PracticeSession";
import { Speaker } from "../models/Speaker";
import { Location } from "../models/Location";

const dataUrl = `${process.env.PUBLIC_URL}/assets/data/data.json`;
const locationsUrl = `${process.env.PUBLIC_URL}/assets/data/locations.json`;

const HAS_LOGGED_IN = "hasLoggedIn";
const HAS_SEEN_TUTORIAL = "hasSeenTutorial";
const USERNAME = "username";

export const getConfData = async () => {
  const response = await Promise.all([fetch(dataUrl), fetch(locationsUrl)]);
  const responseData = await response[0].json();
  const schedule = responseData.schedule[0] as PracticeSession;
  const sessions = parseSessions(schedule);
  const speakers = responseData.speakers as Speaker[];
  const locations = (await response[1].json()) as Location[];
  const allTracks = sessions
    .reduce((all, session) => all.concat(session.tracks), [] as string[])
    .filter((trackName, index, array) => array.indexOf(trackName) === index)
    .sort();

  const data = {
    schedule,
    sessions,
    locations,
    speakers,
    allTracks,
    filteredTracks: [...allTracks],
  };
  return data;
};

export const getUserData = async () => {
  const response = await Promise.all([
    Preferences.get({ key: HAS_LOGGED_IN }),
    Preferences.get({ key: HAS_SEEN_TUTORIAL }),
    Preferences.get({ key: USERNAME }),
  ]);
  const isLoggedin = (await response[0].value) === "true";
  const hasSeenTutorial = (await response[1].value) === "true";
  const username = (await response[2].value) || undefined;
  const data = {
    isLoggedin,
    hasSeenTutorial,
    username,
  };
  return data;
};

export const setIsLoggedInData = async (isLoggedIn: boolean) => {
  await Preferences.set({
    key: HAS_LOGGED_IN,
    value: JSON.stringify(isLoggedIn),
  });
};

export const setHasSeenTutorialData = async (hasSeenTutorial: boolean) => {
  await Preferences.set({
    key: HAS_SEEN_TUTORIAL,
    value: JSON.stringify(hasSeenTutorial),
  });
};

export const setUsernameData = async (username?: string) => {
  if (!username) {
    await Preferences.remove({ key: USERNAME });
  } else {
    await Preferences.set({ key: USERNAME, value: username });
  }
};

function parseSessions(schedule: PracticeSession) {
  const sessions: Session[] = [];
  return sessions;
}
