import React from "react";
import { AudioRecorder } from "../../models/AudioRecorder";
import styles from "./styles.module.css";
import { useSSLContext } from "../../context/SSLContext";

function secondsToTime(secs: number) {
  let hours = Math.floor(secs / (60 * 60));

  let divisor_for_minutes = secs % (60 * 60);
  let minutes = Math.floor(divisor_for_minutes / 60);

  let divisor_for_seconds = divisor_for_minutes % 60;
  let seconds = Math.ceil(divisor_for_seconds);

  let obj = {
    h: hours,
    m: minutes,
    s: seconds,
  };

  return obj;
}

interface recordingButtonProps {
  recorder: AudioRecorder;
  setRecorder: (recorder: AudioRecorder) => void;
}

export const RecordingBtn: React.FC<recordingButtonProps> = ({
  recorder,
  setRecorder,
}) => {
  return (
    <a
      onClick={() => {
        var clone = recorder.clone();
        clone.startRecording();
        setRecorder(clone);
      }}
      className={styles.mic_icon}
    >
      <span className={styles.microphone_icon_sec}>
        <svg
          className={styles.mic_icon_svg}
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 1000 1000"
          enableBackground="new 0 0 1000 1000"
        >
          <g>
            <path d="M500,683.8c84.6,0,153.1-68.6,153.1-153.1V163.1C653.1,78.6,584.6,10,500,10c-84.6,0-153.1,68.6-153.1,153.1v367.5C346.9,615.2,415.4,683.8,500,683.8z M714.4,438.8v91.9C714.4,649,618.4,745,500,745c-118.4,0-214.4-96-214.4-214.4v-91.9h-61.3v91.9c0,141.9,107.2,258.7,245,273.9v124.2H346.9V990h306.3v-61.3H530.6V804.5c137.8-15.2,245-132.1,245-273.9v-91.9H714.4z" />
          </g>
        </svg>
      </span>
    </a>
  );
};

const setupMediaRecorder = (
  r: AudioRecorder,
  setRecorder: (r: AudioRecorder) => void
) => {
  // get permission for media stream
  navigator.mediaDevices
    .getUserMedia({
      audio: true,
    })
    .then((stream: MediaStream) => {
      let clone = r.clone();
      clone.setRecorder(new MediaRecorder(stream));
      setRecorder(clone);
    })
    .catch((err: any) => {
      console.log(err);
    });
};

interface recorderProps {
  title: string;
  handleRecordingStopped: (b: Blob) => void;
  handleReset: () => void;
  url?: string;
  disabled: boolean;
  hideHeader: boolean;
}

export const Recorder: React.FC<recorderProps> = ({
  title,
  handleRecordingStopped,
  handleReset,
  url,
  disabled = false,
  hideHeader = false,
}) => {
  const [seconds, setSeconds] = React.useState<number>(0);
  const [recorder, setRecorder] = React.useState<AudioRecorder>(
    new AudioRecorder(setSeconds)
  );

  const { setSSLError } = useSSLContext();

  const time = secondsToTime(seconds);

  React.useEffect(() => {
    if (navigator.mediaDevices === undefined) {
      setSSLError("Media devices will work only with SSL.");
      return;
    }

    const setupRecorder = async () => {
      if (url === undefined) {
        setupMediaRecorder(recorder, setRecorder);
        return;
      }

      try {
        const response = await fetch(url);
        const blob = await response.blob();
        const clone = recorder.clone();
        await clone.setBlob(blob);
        setupMediaRecorder(clone, setRecorder);
      } catch (error) {
        console.error(error);
      }
    };

    setupRecorder();
  }, [recorder, url]);

  return (
    <div className={styles.recorder_library_box}>
      <div className={styles.recorder_box}>
        <div className={styles.recorder_box_inner}>
          {!hideHeader ? (
            <div className={styles.reco_header}>
              <h4 className={styles.h4}>{title}</h4>
              {/*}<span className={styles.close_icons}>
                </span>*/}
            </div>
          ) : null}
          {recorder.isMediaAvailable() ? (
            <div className={styles.record_section}>
              {disabled ? null : (
                <div className={styles.btn_wrapper}>
                  <button
                    onClick={() => {
                      var clone = recorder.clone();
                      clone.reset();
                      setRecorder(clone);
                      handleReset();
                    }}
                    className={`${styles.btn} ${styles.clear_btn}`}
                  >
                    Clear
                  </button>
                </div>
              )}
              <div className={styles.duration_section}>
                <div className={styles.audio_section}>
                  {recorder.hasRecording() ? (
                    <audio controls preload="auto" key={recorder.getAudioUrl()}>
                      <source src={recorder.getAudioUrl()} type="audio/ogg" />
                      <source src={recorder.getAudioUrl()} type="audio/mpeg" />
                      Your browser does not support the audio element. Please
                      update your browser or use a supported browser.
                    </audio>
                  ) : null}
                </div>
                {disabled ? (
                  <p className={styles.help}>You cannot record at this time.</p>
                ) : (
                  <>
                    <div className={styles.duration}>
                      <span className={styles.mins}>
                        {time.m !== undefined
                          ? `${time.m <= 9 ? "0" + time.m : time.m}`
                          : "00"}
                      </span>
                      <span className={styles.divider}>:</span>
                      <span className={styles.secs}>
                        {time.s !== undefined
                          ? `${time.s <= 9 ? "0" + time.s : time.s}`
                          : "00"}
                      </span>
                    </div>
                    {!recorder.isRecording() ? (
                      <p className={styles.help}>
                        Press the microphone to record
                      </p>
                    ) : null}
                  </>
                )}
              </div>
              {disabled ? null : (
                <>
                  {!recorder.isRecording() ? (
                    <RecordingBtn
                      recorder={recorder}
                      setRecorder={setRecorder}
                    />
                  ) : (
                    <div className={styles.record_controller}>
                      <a
                        onClick={() => {
                          var clone = recorder.clone();
                          clone.stopRecording();
                          setRecorder(clone);
                          handleRecordingStopped(clone.getAudioBlob());
                        }}
                        className={`${styles.icons} ${styles.stop}`}
                      >
                        <span className={styles.stop_icon}></span>
                      </a>
                      {/* This is disabled because mediaRecorder.pause()/resume() APIs are not supported in all browsers yet.
                        <a
                          onClick={
                            !pauseRecord
                              ? e => this.handleAudioPause(e)
                              : e => this.handleAudioStart(e)
                          }
                          className={`${styles.icons} ${styles.pause}`}
                        >
                          {pauseRecord ?
                            <span className={styles.play_icons}></span> :
                            <span className={styles.pause_icons}></span>}
                        </a>
                    */}
                    </div>
                  )}
                </>
              )}
            </div>
          ) : (
            <p
              style={{
                color: "red",
                marginTop: 30,
                fontSize: 25,
                textAlign: "center",
              }}
            >
              No recording media found!
              <br />
              Please give permissions to use your microphone and refresh this
              page.
            </p>
          )}
        </div>
      </div>
    </div>
  );
};
