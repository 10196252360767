import React, { useState } from "react";
import { IonLabel, IonInput, InputChangeEventDetail } from "@ionic/react";
import {
  PracticeSession,
  QuestionPractice,
} from "../../models/PracticeSession";

interface OneWordAnswerInputProps {
  practice: QuestionPractice;
  pSession: PracticeSession;
  onTextInputChange: (value: string) => void;
  answerSubmitted: boolean;
}

function OneWordAnswerInput({
  practice,
  pSession,
  onTextInputChange,
  answerSubmitted,
}: OneWordAnswerInputProps) {
  const [inputValue, setInputValue] = useState(
    practice.userResponseText !== undefined ? practice.userResponseText : ""
  );

  const handleInputChange = (event: CustomEvent<InputChangeEventDetail>) => {
    const newValue = event.detail.value || "";
    setInputValue((prevValue) => {
      onTextInputChange(newValue);
      pSession.setUserResponseTextOnCurrentPractice(newValue);
      return newValue;
    });
  };

  return (
    <>
      <IonLabel>Your Answer: </IonLabel>
      <IonInput
        name="option_oneword"
        type="text"
        placeholder="Write your answer here ..."
        value={inputValue}
        onIonChange={handleInputChange}
        disabled={answerSubmitted}
      />
    </>
  );
}

export default OneWordAnswerInput;
