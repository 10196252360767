import React, { useState } from "react";
import {
  IonButton,
  IonCol,
  IonGrid,
  IonItem,
  IonLabel,
  IonModal,
  IonRow,
  IonText,
} from "@ionic/react";
import { PracticeSession } from "../../models/PracticeSession";
import { UploadUserFile } from "../../papiclient/client";
import "./AnswerSheet.css";

interface AnswerSheetProps {
  pSession: PracticeSession;
  showUploadDialog: boolean;
  setShowUploadDialog: React.Dispatch<React.SetStateAction<boolean>>;
  setPracticeSession: (ps: PracticeSession) => void;
}

export const AnswerSheet: React.FC<AnswerSheetProps> = ({
  pSession,
  showUploadDialog,
  setShowUploadDialog,
  setPracticeSession,
}) => {
  const [file, setFile] = useState<File | null>(null);

  const handleFileInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.files) {
      setFile(event.target.files[0]);
    }
  };

  const handleUpload = () => {
    if (file) {
      UploadUserFile(file).then((res) => {
        if (res.id) {
          var clone = pSession.clone();
          var p = pSession.getCurrentPractice();
          p.userResponseText = undefined;
          p.userSelectedAnswerIndices = undefined;
          clone.setUserResponseFileIDOnCurrentPractice(res.id);
          setPracticeSession(clone);
          setShowUploadDialog(false);
        }
      });
    }
  };

  return (
    <>
      <IonModal
        isOpen={showUploadDialog}
        onDidDismiss={() => setShowUploadDialog(false)}
        className="answer-sheet-modal auto-height"
      >
        <IonItem>
          <h1>
            <IonLabel>Upload Answer Sheet</IonLabel>
          </h1>
        </IonItem>
        <IonItem>
          <IonGrid>
            <IonRow>
              <IonCol>
                <IonLabel>
                  <IonText>Select File : </IonText>
                  <input
                    type="file"
                    accept=".pdf"
                    onChange={handleFileInputChange}
                  />
                </IonLabel>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonText>
                  <p style={{ fontSize: "small" }}>
                    You can upload a single answer sheet on any exam. You can
                    replace the previous answer sheet by uploading it again
                    while the exam is ongoing.
                  </p>
                </IonText>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol className="ion-text-end">
                <IonButton
                  size="small"
                  color={"primary"}
                  class="upload-button"
                  onClick={handleUpload}
                  style={{ textTransform: "none" }}
                >
                  Upload
                </IonButton>
                <IonButton
                  style={{ textTransform: "none" }}
                  size="small"
                  color={"light"}
                  class="cancel-button"
                  onClick={() => setShowUploadDialog(false)}
                >
                  Cancel
                </IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonItem>
      </IonModal>
    </>
  );
};

export default AnswerSheet;
