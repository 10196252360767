import React from "react";
import {
  IonModal,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonButton,
  IonIcon,
  IonGrid,
  IonRow,
  IonCol,
  IonText,
  IonFooter,
  IonLabel,
} from "@ionic/react";
import { closeOutline } from "ionicons/icons";
import useFetchTopics from "./useFetchTopics";
import TopicList from "./TopicList";
import "./infomodal.css";

interface InfoModalProps {
  isOpen: boolean;
  onClose: () => void;
  questionId: number;
}

const InfoModal: React.FC<InfoModalProps> = ({
  isOpen,
  onClose,
  questionId,
}) => {
  const { topics, loading } = useFetchTopics(questionId, isOpen);

  return (
    <IonModal  id="study-material-modal" isOpen={isOpen} onDidDismiss={onClose} className="ion-padding">
      <IonHeader>
        <IonToolbar>
          <IonGrid>
            <IonRow>
              <IonCol>
                <IonTitle className="ion-margin-top custom-text-color">
                  <strong>Topics related to this question</strong>
                </IonTitle>
              </IonCol>
              <IonCol className="ion-text-end">
                <IonButton fill="clear" onClick={onClose}>
                  <IonIcon icon={closeOutline} />
                </IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        {loading ? (
          <p>Loading...</p>
        ) : topics.length > 0 ? (
          <TopicList topics={topics} />
        ) : (
          <IonRow className="ion-text-center">
            <IonCol>
              <IonText>No topics found.</IonText>
            </IonCol>
          </IonRow>
        )}
      </IonContent>
      <IonFooter className="ion-padding">
        <IonToolbar className="ion-text-end">
          <IonButton color="medium" fill="clear" onClick={onClose}>
          <IonLabel className="ion-text-bold">Close</IonLabel>
          </IonButton>
        </IonToolbar>
      </IonFooter>
    </IonModal>
  );
};

export default InfoModal;
