import React from 'react';
import { IonButton } from '@ionic/react';
import './Pagination.css';

interface PaginationProps {
  currentPage: number;
  totalPages: number;
  onPageChange: (newPage: number) => void;
  rowsPerPage: number;
  handleRowsPerPageChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  rowsPerPageOptions: number[];
  darkMode:boolean
}

const Pagination: React.FC<PaginationProps> = ({ darkMode, currentPage, totalPages, onPageChange, rowsPerPage, handleRowsPerPageChange, rowsPerPageOptions}) => {
  const handlePrevPage = () => {
    onPageChange(currentPage - 1);
  };

  const handleNextPage = () => {
    onPageChange(currentPage + 1);
  };

  return (
    <div className="ion-text-center ion-padding-bottom">
    <div className="horizontal-line"></div>
    <div className="pagination">
            <label  className="ion-padding-end text-small">Rows Per Page:</label>
            <select className={`text-small ${darkMode ? 'dark-mode-font-color' : ''}`}
              value={rowsPerPage} onChange={handleRowsPerPageChange}>
              {rowsPerPageOptions.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}

            </select>

            <button
            onClick={handlePrevPage}
            className={`ion-padding pagination-icon custom-btn ${darkMode ? 'dark-mode-font-color' : ''}`}
            disabled={currentPage === 1}
        >
          &lsaquo;
        </button>

        <span className='text-small'>{`Page ${currentPage} of ${totalPages}`}</span>

        <button
          onClick={handleNextPage}
          className={`ion-padding pagination-icon custom-btn ${darkMode ? 'dark-mode-font-color' : ''}`}
          disabled={currentPage === totalPages}
        >
          &rsaquo;
        </button>
            </div>
          </div>
  );
};

export default Pagination;
