import React from "react";

interface IHeroContent {
  institutionName: string;
}
const HeroContent: React.FC<IHeroContent> = ({ institutionName }) => {
  const redirectTo = (path: string) => {
    window.location.href = path;
  };
  return (
    <div className="flex flex-col items-center justify-center text-center text-white hero-content md:pr-10">
      <h2 className="mb-4 text-5xl" id="branding_instt_name">
        {institutionName ? institutionName : "W o r d l i n e"}
      </h2>
      <p className="mb-6 text-xl">
        Study online wherever you go. Our clean and simple interface gets out of
        the way and lets you learn.
      </p>

      <button
        onClick={() => redirectTo("/register")}
        className="bg-white text-[#888888] px-6 py-2 rounded-md text-lg font-medium flex items-center justify-center"
      >
        <i className="mr-2 fa fa-sign-in"></i>
        Create Your Account Today
      </button>
    </div>
  );
};

export default HeroContent;
