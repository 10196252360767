import React from "react";
import "./Logo.css";
import { IonImg } from "@ionic/react";

const Logo: React.FC = () => {
  return (
    <div className="logo">
      <IonImg
        src={process.env.PUBLIC_URL + "/assets/img/wordline-inverted.png"}
        alt="Wordline Logo"
        class="logo-img md"
      ></IonImg>
    </div>
  );
};

export default Logo;
