import React from "react";
import {
  IonHeader,
  IonRow,
  IonCol,
  IonGrid,
  IonBadge,
  IonItem,
  IonLabel,
  IonButton,
} from "@ionic/react";
import "./ReviewPracticeCard.css";
import { PracticeSession } from "../../models/PracticeSession";
import { connect } from "../../data/connect";


interface StateProps {
  darkMode: boolean;
}

interface ReviewPracticeCardProps extends StateProps {
  darkMode: boolean;
  pSession: PracticeSession;
  setPracticeSession: (ps: PracticeSession) => void;
  setShowReviewPage: (value: boolean) => void;
}

const getStatusBadgeColor = (item: any) => {
  if (item.practiceableType === "App\\Models\\Question") {
    if (item.userSkipped) {
      return "warning";
    } else if (
      (item.userSelectedAnswerIndices &&
        item.userSelectedAnswerIndices.length > 0) ||
      item.userResponseFileId !== undefined ||
      item.userResponseText !== undefined
    ) {
      return "success";
    } else {
      return "danger";
    }
  }
  return "default";
};

const getQuestionSummary = (item: any) => {
  if (item.practiceableType === "App\\Models\\Question") {
    return item.question.summary;
  }
  return item.wordQuestion.word;
};

export const ReviewPracticeCard: React.FC<ReviewPracticeCardProps> = ({
  darkMode,
  pSession,
  setPracticeSession,
  setShowReviewPage,
}) => {
  const handleQuestionSummaryClick = (item: any) => {
    var clone = pSession.clone();
    clone.setCurrentPracticeID(item.id);
    setPracticeSession(clone);
    setShowReviewPage(false);
  };

  return (
    <>
      <div>
        <IonGrid id="reviewPracticeTable" class="table-container">
          <table>
            <IonHeader className={`table-header ion-margin-bottom ${darkMode ? 'dark-mode-color' : ''}`}>
              <IonRow>
                <IonCol style={{ flex: "0 0 5%" }}>#</IonCol>
                <IonCol>Questions Summary</IonCol>
                <IonCol>Status</IonCol>
              </IonRow>
            </IonHeader>
            <tbody>
              {pSession.knownPractices.map((item, index) => (
                <IonRow key={index}>
                  <IonCol style={{ flex: "0 0 5%" }}>{index + 1}</IonCol>
                  <IonCol>
                    <IonLabel
                      id={ `quesSequence_${index+1}`}
                      style={{ color: "#337ab7", cursor: "pointer" }}
                      onClick={() => handleQuestionSummaryClick(item)}
                    >
                      {getQuestionSummary(item)}
                    </IonLabel>
                  </IonCol>
                  <IonCol>
                    <IonBadge color={getStatusBadgeColor(item)}>
                      {item.practiceableType === "App\\Models\\Question" &&
                        (item.userSkipped
                          ? "Skipped"
                          : (item.userSelectedAnswerIndices &&
                              item.userSelectedAnswerIndices.length > 0) ||
                            item.userResponseFileId !== undefined ||
                            item.userResponseText !== undefined
                          ? "Attempted"
                          : "Unattempted")}{" "}
                    </IonBadge>
                  </IonCol>
                </IonRow>
              ))}
            </tbody>
          </table>
        </IonGrid>
      </div>
    </>
  );
};
export default connect<ReviewPracticeCardProps, StateProps>({
  mapStateToProps: (state) => ({
    darkMode: state.user.darkMode,
  }),
  component: ReviewPracticeCard,
});
