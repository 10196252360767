import React from "react";
import { IonIcon } from "@ionic/react";

interface Statistic {
  label: string;
  value: number | string;
  icon: string;
}

interface StatisticsProps {
  stats: Statistic[];
}

const labelColorMapping: { [key: string]: string } = {
  "Questions Uploaded": "text-[#34c73b]",
  "Institutions Registered": "text-[#797979]",
  "Instructors Available": "text-[#d74548]",
  "Students Enrolled": "text-[#3fb7ee]",
  "Practices held by students": "text-[#f7c836]",
};

const Statistics: React.FC<StatisticsProps> = ({ stats }) => {
  return (
    <div className="grid grid-cols-1  sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mt-6">
      {stats.map((stat, index) => (
        <div
          key={index}
          className="flex justify-between items-center bg-white py-6 px-8 rounded-md shadow-sm border border-[#E6EBEC]"
        >
          <div>
            <div className="font-bold text-[#797979] text-2xl mb-1">
              {stat.value}
            </div>
            <div className="text-[#797979] text-sm">{stat.label}</div>
          </div>

          <i
            className={`${stat.icon} text-5xl ${labelColorMapping[stat.label]}`}
          ></i>
        </div>
      ))}
    </div>
  );
};

export default Statistics;
