import React, { useState } from "react";

import {
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonIcon,
  IonButton,
  IonButtons,
  IonSegment,
  IonSegmentButton,
} from "@ionic/react";
import {
  cloudUploadOutline,
  playBackSharp,
  playForwardSharp,
  playSharp,
} from "ionicons/icons";
import { useHistory } from "react-router";
import {
  LearningMode,
  PracticeSession,
  PracticeTemplateSection,
  PapiError,
} from "../../models/PracticeSession";

import { titleCase } from "title-case";
import { StatusCodes } from "http-status-codes";
import { QuestionCard } from "./QuestionCard";
import { DictionaryWordCard } from "./DictionaryWordCard";
import { ReviewPracticeCard } from "./ReviewPracticeCard";
import { AnswerSheet } from "./AnswerSheet";

import "./PracticeSession.css";

export interface EndButtonProps {
  pSession: PracticeSession;
}

const EndButton: React.FC<EndButtonProps> = ({ pSession }) => {
  return (
    <IonButton
      className="button-text-normal"
      style={{ textTransform: "none" }}
      id="endPracticeBtn"
      color={pSession.canEndPractice() ? "success" : "danger"}
      size="small"
      slot="end"
      fill="solid"
      onClick={() => {
        fetch(`/papi/institution/practice-session/${pSession.id}/stop`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
          .then((resp: Response) => {
            if (resp.status !== StatusCodes.OK) {
              return Promise.reject({
                code: resp.status,
                message: `failed to end the practice-session: ${resp.status} / ${resp.statusText}`,
              } as PapiError);
            }

            window.location.assign(`/PracticeSession/Summary/${pSession.id}`);
          })
          .catch((err: PapiError) => {
            console.log(err);
          });
      }}
    >
      End Practice
    </IonButton>
  );
};

type PracticeSessionComponentProps = {
  pSession: PracticeSession | undefined;
  setPracticeSession: (ps: PracticeSession) => void;
  darkMode: boolean;
};

export const PracticeSessionComponent = ({
  pSession,
  darkMode,
  setPracticeSession,
}: PracticeSessionComponentProps) => {
  const history = useHistory();
  const [showReviewPage, setShowReviewPage] = useState<boolean>(false);
  const [showUploadDialog, setShowUploadDialog] = useState(false);

  if (!pSession) {
    // Handle the loading state or any other scenario where pSession is undefined
    return <div>Loading...</div>;
  }

  const practice = pSession.getCurrentPractice();
  let title = "";
  var section: PracticeTemplateSection | null = null;

  if (pSession.learningMode === LearningMode.PracticeTemplate) {
    if (practice.practiceableType !== "App\\Models\\Question") {
      throw new Error(
        "practice-template based practices cannot be of word-question type"
      );
    }

    if (pSession.practiceTemplate === undefined) {
      throw "practice-template is not set for a practice-session in template based learning mode";
    }

    if (pSession.isLiveExam()) {
      title = "Live Exam: ";
    }

    title += `<strong>${pSession.practiceTemplate.name}</strong>`;

    section = pSession.practiceTemplate.findSectionOfQuestionById(
      practice.question.id
    );
    if (section === null) {
      throw "unable to find the practice-template-section for the current question of the practice-template";
    }
  } else {
    title = `<strong>${titleCase(pSession.name)}</strong>` + " Practice";
  }

  title += " ";
  if (practice.practiceableType === "App\\Models\\DictionaryWord") {
    title +=
      `<strong>${pSession.currentPracticeIndex() + 1}</strong>` +
      ` of ${pSession.totalWordQuestions} Dictionary Words`;
  } else {
    // all word questions are drained in the beginning
    title +=
      `<strong>${
        pSession.currentPracticeIndex() + 1 - pSession.totalWordQuestions
      }</strong>` +
      ` of <strong>${
        pSession.totalQuestions - pSession.totalWordQuestions
      }</strong> Questions`;
  }

  if (practice.isAdaptiveSelection) {
    title += " [adaptive]";
  }

  let practiceToolbar = (
    <IonToolbar>
      <IonButtons slot="start">
        {!showReviewPage && (
          <IonButton
            className="button-text-normal"
            fill="solid"
            id="backButton"
            onClick={() => {
              var clone = pSession.clone();
              clone.changeToPreviousPractice();
              setPracticeSession(clone);
            }}
            disabled={pSession.atFirstPractice()}
          >
            <IonIcon slot="start" icon={playBackSharp}></IonIcon>
            Back
          </IonButton>
        )}
        {!showReviewPage && (
          <IonButton
            className="button-text-normal"
            fill="solid"
            id="nextButton"
            onClick={() => {
              var clone = pSession.clone();
              clone.changeToNextPractice();
              setPracticeSession(clone);
            }}
            disabled={pSession.atLastKnownPractice()}
          >
            <IonIcon slot="start" icon={playForwardSharp}></IonIcon>
            Next
          </IonButton>
        )}
        {!showReviewPage && !pSession.atLastKnownPractice() && (
          <IonButton
            className="button-text-normal"
            fill="solid"
            onClick={() => {
              var clone = pSession.clone();
              clone.changeToLastPractice();
              setPracticeSession(clone);
            }}
          >
            <IonIcon slot="start" icon={playSharp}></IonIcon>
            Go To Current
          </IonButton>
        )}
      </IonButtons>
      <IonButtons slot="end">
        {pSession.learningMode === LearningMode.PracticeTemplate ? (
          <>
            <IonButton
              style={{ textTransform: "none" }}
              id="reviewPracticeBtn"
              color="primary"
              size="small"
              slot="end"
              fill={showReviewPage === false ? "outline" : "solid"}
              onClick={() => {
                setShowReviewPage(!showReviewPage);
              }}
            >
              {showReviewPage ? "Back to Practice" : "Review Practice"}
            </IonButton>
            {practice.practiceableType === "App\\Models\\Question" &&
            practice.question.pattern.isEssay() ? (
              <IonButton
                style={{ textTransform: "none", "--ion-text-color": "#7d4bc5" }}
                id="answerSheetBtn"
                size="small"
                slot="end"
                fill="solid"
                onClick={() => setShowUploadDialog(true)}
              >
                {" "}
                <IonIcon icon={cloudUploadOutline} slot="start" />
                Answer Sheet
              </IonButton>
            ) : null}
          </>
        ) : (
          <>
            {practice.practiceableType === "App\\Models\\DictionaryWord" ||
            section === null ||
            section.timeLimitSeconds === 0 ? (
              <IonButton
                style={{ textTransform: "none" }}
                color="primary"
                size="small"
                slot="end"
                fill="solid"
                id="pauseButton"
                onClick={() => {
                  history.push(`/PracticeSession/List`);
                }}
              >
                Pause Practice
              </IonButton>
            ) : null}
          </>
        )}
        <AnswerSheet
          pSession={pSession}
          showUploadDialog={showUploadDialog}
          setShowUploadDialog={setShowUploadDialog}
          setPracticeSession={setPracticeSession}
        />
        <EndButton pSession={pSession} />
      </IonButtons>
    </IonToolbar>
  );

  let sectionSegmentToolbar = null;
  if (pSession.learningMode === LearningMode.PracticeTemplate) {
    if (pSession.practiceTemplate === undefined) {
      throw "practice-template is not set for a practice-session in template based learning mode";
    }

    if (section === null) {
      sectionSegmentToolbar = (
        <IonSegmentButton value="n/a">N/A</IonSegmentButton>
      );
    } else {
      sectionSegmentToolbar = pSession.practiceTemplate.sections.map((s) => (
        <IonSegmentButton
          key={s.id}
          value={`section-${s.id}`}
          disabled={s.id !== section?.id}
        >
          {s.name}
        </IonSegmentButton>
      ));
    }

    sectionSegmentToolbar = (
      <IonToolbar>
        <IonSegment value={section === null ? "n/a" : `section-${section.id}`}>
          {sectionSegmentToolbar}
        </IonSegment>
      </IonToolbar>
    );
  }

  if (
    practice.practiceableType === "App\\Models\\DictionaryWord" &&
    pSession.wordlist === undefined
  ) {
    throw "practice-session word-info is undefined but we have a word question in the known-questions";
  }

  return (
    <>
      <IonHeader translucent={true}>
        <IonToolbar>
          <IonTitle dangerouslySetInnerHTML={{ __html: title }} />
        </IonToolbar>
      </IonHeader>

      <IonContent>
        {pSession.isLiveExam() ? (
          <span className="pull-right"></span>
        ) : (
          <h4>
            {practice.practiceableType === "App\\Models\\DictionaryWord" &&
            pSession.wordlist !== undefined ? (
              <p>
                {" "}
                Wordlist : <small> {pSession.wordlist.name}</small>{" "}
              </p>
            ) : (
              <>
                <p>
                  {" "}
                  Topic : <small> {pSession.topic.name}</small>{" "}
                </p>
                <p>
                  {" "}
                  Exam{pSession.exams.length > 1 ? "s" : ""} :{" "}
                  <small> {pSession.exams.map((e) => e.name + " ")} </small>
                </p>
              </>
            )}
          </h4>
        )}

        {practiceToolbar}

        {showReviewPage && (
          <ReviewPracticeCard
            darkMode={darkMode}
            pSession={pSession}
            setPracticeSession={setPracticeSession}
            setShowReviewPage={setShowReviewPage}
          />
        )}
        {!showReviewPage && (
          <>
            {sectionSegmentToolbar}

            {practice.practiceableType === "App\\Models\\DictionaryWord" ? (
              <DictionaryWordCard
                pSession={pSession}
                setPracticeSession={setPracticeSession}
              />
            ) : (
              <QuestionCard
                pSession={pSession}
                setPracticeSession={setPracticeSession}
                setShowReviewPage={setShowReviewPage}
              />
            )}
          </>
        )}
      </IonContent>
    </>
  );
};
