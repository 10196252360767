import React, { createContext, useContext, useState, ReactNode } from "react";

interface SSLContextProps {
  sslError: string | null;
  setSSLError: (error: string | null) => void;
}

const SSLContext = createContext<SSLContextProps | undefined>(undefined);

export const SSLProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [sslError, setSSLError] = useState<string | null>(null);

  return (
    <SSLContext.Provider value={{ sslError, setSSLError }}>
      {children}
    </SSLContext.Provider>
  );
};

export const useSSLContext = () => {
  const context = useContext(SSLContext);
  if (!context) {
    throw new Error("useSSLContext must be used within an SSLProvider");
  }
  return context;
};
