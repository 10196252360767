import { now } from "@ionic/core/dist/types/utils/helpers";
import { error } from "console";
import { abort } from "process";
import { InitializeNextPracticeOfPracticeSessionByID } from "../papiclient/client";

export interface PapiPracticeSessionGETResponse {
  name: string;
  learningMode: string;
  practiceTemplateId: number;
  wordlistId: number;
  topicId: number;
  examIDs: number[];
  totalQuestions: number;
  totalWordQuestions: number;
  status: string;
}

export interface PapiPracticeSessionsGETResponseItem {
  id: number;
  name: string;
  topicName: string;
  examNames: string[];
  totalWordQuestions: number;
  totalQuestions: number;
  status: string;
  canCloneThisSession: boolean;
  wordlistName?: string;
}

export class PracticeSessionListInfoItem {
  id: number;
  name: string;
  topicName: string;
  examNames: string[];
  totalWordQuestions: number;
  totalQuestions: number;
  status: Status;
  canCloneThisSession: boolean;
  wordlistName?: string;

  constructor(
    id: number,
    name: string,
    topicName: string,
    examNames: string[],
    totalWordQuestions: number,
    totalQuestions: number,
    status: Status,
    canCloneThisSession: boolean,
    wordlistName?: string
  ) {
    this.id = id;
    this.name = name;
    this.topicName = topicName;
    this.examNames = examNames;
    this.totalWordQuestions = totalWordQuestions;
    this.totalQuestions = totalQuestions;
    this.status = status;
    this.canCloneThisSession = canCloneThisSession;
    this.wordlistName = wordlistName;
  }

  canResume(): boolean {
    return this.status == Status.InProgress;
  }

  canCreateNewPracticeFrom(): boolean {
    return this.canCloneThisSession;
  }
}

export interface PapiPracticeSessionsGETResponse {
  items: PapiPracticeSessionsGETResponseItem[];
}

export interface PapiFilePOSTResponse {
  id: number;
}

export interface PapiPracticesPOSTResponse {
  practiceId: number;
}

export interface PapiPracticeAnswerSubmitPOSTRequest {
  responseTime: number; // milliseconds
  studentResponseOptionIndices?: number[];
  studentResponseText?: string;
  studentResponseFileId?: number;
}

export interface PapiPracticeSkipPOSTRequest {
  responseTime: number; // milliseconds
}

interface PapiWordPromptPracticeInfo {
  typ: "prompt";
  // no data for this word question type
}

interface PapiWordMultiMeaningPracticeInfoData {
  wordOptionIDs: number[];
  responseWordId: number;
}

interface PapiWordMultiMeaningPracticeInfo {
  typ: "multi-meaning";
  data: PapiWordMultiMeaningPracticeInfoData;
}

interface PapiWordReverseMeaningPracticeInfo {
  typ: "reverse-meaning";
  data: PapiWordMultiMeaningPracticeInfoData;
}

export type PapiWordInfo =
  | PapiWordPromptPracticeInfo
  | PapiWordMultiMeaningPracticeInfo
  | PapiWordReverseMeaningPracticeInfo;

export interface PapiPracticeGETResponse {
  practiceableId: number;
  practiceableType: string;
  sequence: number;
  answeredCorrectly: boolean;
  userSelectedAnswerIndices?: number[];
  userResponseText?: string;
  userResponseFileId?: number;
  adaptive: boolean;
  userSkipped: boolean;
  wordInfo?: PapiWordInfo;
}

export interface PapiError extends Error {
  name: "PapiError";
  code: number;
}

export enum LearningMode {
  RandomQuestions,
  ReviseAnsweredQuestions,
  ReviseUnattemptedQuestions,
  ReviseIncorrectlyAnsweredQuestions,
  SmartPractice,
  Adaptive,
  PracticeTemplate,
}

export function LearningModeFromString(v: string): LearningMode {
  const m = LearningMode[v as keyof typeof LearningMode];
  if (m === undefined) {
    throw new Error("unexpected learning-mode: " + v);
  }

  return m;
}

export enum Status {
  InProgress,
  Completed,
}

export function StatusFromString(v: string): Status {
  const s = Status[v as keyof typeof Status];
  if (s === undefined) {
    throw new Error("unexpected learning-mode: " + v);
  }

  return s;
}

export enum WordQuestionType {
  Prompt = "prompt",
  MultiMeaning = "multi-meaning",
  ReverseMeaning = "reverse-meaning",
}

export enum DifficultyLevel {
  Easy = 1,
  Medium,
  Difficult,
}

export function DifficultyLevelToString(l: DifficultyLevel): string {
  switch (l) {
    case DifficultyLevel.Easy:
      return "Easy";
    case DifficultyLevel.Medium:
      return "Medium";
    case DifficultyLevel.Difficult:
      return "Difficult";
    default:
      throw "invalid difficulty-level";
  }
}

export function DifficultyLevelToColor(l: DifficultyLevel): string {
  switch (l) {
    case DifficultyLevel.Easy:
      return "secondary";
    case DifficultyLevel.Medium:
      return "primary";
    case DifficultyLevel.Difficult:
      return "warning";
    default:
      throw "invalid difficulty-level";
  }
}

export interface SectionQuestionInfo {
  id: number;
  score?: number;
}

export class PracticeTemplateSection {
  id: number;
  name: string;
  timeLimitSeconds: number;
  questionInfos: SectionQuestionInfo[];
  negativeMarkingPercentage?: number;

  constructor(
    id: number,
    name: string,
    questionInfos: SectionQuestionInfo[],
    timeLimitSeconds: number,
    negativeMarkingPercentage?: number
  ) {
    this.id = id;
    this.negativeMarkingPercentage = negativeMarkingPercentage;
    this.name = name;
    this.timeLimitSeconds = timeLimitSeconds;
    this.questionInfos = questionInfos;
  }

  findSectionQuestionInfoById(id: number): SectionQuestionInfo | null {
    for (const i of this.questionInfos) {
      if (i.id === id) {
        return i;
      }
    }

    return null;
  }
}

export interface GroupedQuestionInfo {
  groupParent: Question;
  subQuesSeq: number;
}

export enum QuestionPatternTypes {
  MCQ = "Multiple Option Multiple Choice",
  MOSC = "Multiple Option Single Choice",
  YN = "Yes/No",
  OneWord = "One Word",
  Group = "Group",
  Essay = "Essay",
  AudioResponse = "Audio Response",
}

export class QuestionPattern {
  id: number;
  typ: QuestionPatternTypes;
  helpText: string;

  constructor(id: number, name: string, helpText: string) {
    if (!Object.values(QuestionPatternTypes).some((v: string) => v === name)) {
      throw new Error(`unexpected question pattern: ${name}`);
    }

    this.id = id;
    this.typ = <QuestionPatternTypes>name;

    if (this.typ === undefined) {
      throw new Error(`failed to determine the pattern from name: ${name}`);
    }

    this.helpText = helpText;
  }

  name(): string {
    return this.typ.toString();
  }

  isEssay(): boolean {
    return this.typ === QuestionPatternTypes.Essay;
  }
}

export type QuestionOption = {
  id: number;
  text: string;
};

export type Question = {
  id: number;
  summary: string;
  description: string;
  difficultyLevel: DifficultyLevel;
  groupedQuestionInfo?: GroupedQuestionInfo;
  options?: QuestionOption[];
  pattern: QuestionPattern;
  answerExplanation?: string;
  answer: string[];
};

export interface DictionaryWord {
  json: any;
  id: number;
  name: string;
  meaning: string;
  typ: string;
}

export type WordQuestion = {
  word: DictionaryWord;
  pattern: WordQuestionType;
  options?: QuestionOption[];
};

export type QuestionPractice = {
  practiceableType: "App\\Models\\Question";
  question: Question;
  userResponseText?: string;
  userResponseFileId?: number;
  userSkipped: boolean;
};

export type WordQuestionPractice = {
  practiceableType: "App\\Models\\DictionaryWord";
  wordQuestion: WordQuestion;
};

export type Practice = (QuestionPractice | WordQuestionPractice) & {
  id: number;
  isAdaptiveSelection: boolean;
  sequence: number;
  userSeenAt?: Date; // user has seen the practice at this time
  userSelectedAnswerIndices?: number[]; // user has selected these answer indices
  answerSubmitted: boolean;
  userResponseFileId?: number;
  userResponseText?: string;
};

export interface PapiPracticeTemplateGETResponse {
  name: string;
  status: string;
  sectionIds: number[];
  liveExamStartsAt?: Date;
}

export enum PracticeTemplateStatus {
  Draft,
  Released,
  Obsolete,
}

export function PracticeTemplateStatusFromString(
  v: string
): PracticeTemplateStatus {
  const s = PracticeTemplateStatus[v as keyof typeof PracticeTemplateStatus];
  if (s === undefined) {
    throw new Error("unexpected learning-mode: " + v);
  }

  return s;
}

export class PracticeTemplate {
  name: string;
  sections: PracticeTemplateSection[];
  status: PracticeTemplateStatus;

  liveExamStartsAt?: Date;

  constructor(
    name: string,
    sections: PracticeTemplateSection[],
    status: PracticeTemplateStatus,
    liveExamStartsAt?: Date
  ) {
    this.name = name;
    this.sections = sections;
    this.status = status;
    this.liveExamStartsAt = liveExamStartsAt;
  }

  findSectionById(id: number): PracticeTemplateSection | null {
    for (const s of this.sections) {
      if (s.id === id) {
        return s;
      }
    }

    return null;
  }

  findSectionOfQuestionById(id: number): PracticeTemplateSection | null {
    for (const s of this.sections) {
      for (const i of s.questionInfos) {
        if (i.id === id) {
          return s;
        }
      }
    }

    return null;
  }

  isLiveExam(): boolean {
    return this.liveExamStartsAt !== undefined;
  }

  canCreateNewPracticeFrom(): boolean {
    return (
      this.status === PracticeTemplateStatus.Released && !this.isLiveExam()
    );
  }
}

export interface Wordlist {
  name: string;
}

export interface Topic {
  name: string;
}

export interface Exam {
  name: string;
}

export class PracticeSession {
  id: number;
  name: string;
  learningMode: LearningMode;
  practiceTemplate?: PracticeTemplate;
  wordlist?: Wordlist;
  topic: Topic;
  exams: Exam[];

  private currentPracticeID?: number;
  private status: Status;

  knownPractices: Practice[];
  totalWordQuestions: number;
  totalQuestions: number;

  constructor(
    id: number,
    name: string,
    learningMode: LearningMode,
    topic: Topic,
    exams: Exam[],
    knownPractices: Practice[],
    totalWordQuestions: number,
    totalQuestions: number,
    status: Status,
    wordlist?: Wordlist,
    practiceTemplate?: PracticeTemplate
  ) {
    this.id = id;
    this.name = name;
    this.learningMode = learningMode;
    this.wordlist = wordlist;
    this.topic = topic;
    this.exams = exams;
    this.knownPractices = knownPractices;
    this.totalWordQuestions = totalWordQuestions;
    this.totalQuestions = totalQuestions;
    this.status = status;
    this.practiceTemplate = practiceTemplate;

    // default set the last element as the current practice
    var lastPracticeID = this.knownPractices.at(-1)?.id;
    if (lastPracticeID !== undefined) {
      this.setCurrentPracticeID(lastPracticeID);
    }
  }

  clone(): PracticeSession {
    var clone = new PracticeSession(
      this.id,
      this.name,
      this.learningMode,
      this.topic,
      this.exams,
      this.knownPractices,
      this.totalWordQuestions,
      this.totalQuestions,
      this.status,
      this.wordlist,
      this.practiceTemplate
    );

    if (this.currentPracticeID !== undefined) {
      clone.setCurrentPracticeID(this.currentPracticeID);
    }

    return clone;
  }

  /**
   * Whether the practice session is in-progress/completed but the first question has
   * not yet been pulled.
   */
  hasStarted(): boolean {
    return this.knownPractices.length > 0 || this.status === Status.Completed;
  }

  canResume(): boolean {
    return this.status == Status.InProgress;
  }

  canEndPractice(): boolean {
    return (
      !this.hasMorePractices() &&
      this.knownPractices.length > 0 &&
      this.knownPractices[this.knownPractices.length - 1].answerSubmitted
    );
  }

  async start(): Promise<void> {
    if (this.hasStarted()) {
      throw new Error("this practice session has already started");
    }

    if (this.status !== Status.InProgress) {
      throw new Error("this practice session is not in-progress");
    }

    // when we do not have any practice initialized then initialize one now
    return InitializeNextPracticeOfPracticeSessionByID(this.id).then(
      (p: Practice) => {
        this.appendPractice(p);
        this.setCurrentPracticeID(p.id);
      }
    );
  }

  getPracticeByID(id: number): Practice {
    for (const p of this.knownPractices) {
      if (p.id === id) {
        return p;
      }
    }

    throw new Error("practice ID " + id.toString() + " is not known");
  }

  getCurrentPracticeID(): number {
    if (this.currentPracticeID === undefined) {
      throw new Error("current practice is undefined");
    }

    return this.currentPracticeID;
  }

  getCurrentPractice(): Practice {
    return this.getPracticeByID(this.getCurrentPracticeID());
  }

  changeToPreviousPractice() {
    var prevPracticeIdx = this.currentPracticeIndex() - 1;

    if (prevPracticeIdx >= 0) {
      this.setCurrentPracticeID(this.knownPractices[prevPracticeIdx].id);
    }
  }

  changeToLastPractice() {
    if (this.knownPractices.length > 0) {
      this.setCurrentPracticeID(
        this.knownPractices[this.knownPractices.length - 1].id
      );
    }
  }

  changeToNextPractice() {
    var nextPracticeIdx = this.currentPracticeIndex() + 1;

    if (nextPracticeIdx < this.knownPractices.length) {
      this.setCurrentPracticeID(this.knownPractices[nextPracticeIdx].id);
    }
  }

  currentPracticeIndex(): number {
    for (var i = 0; i < this.knownPractices.length; i++) {
      if (this.knownPractices[i].id === this.getCurrentPracticeID()) {
        return i;
      }
    }

    throw new Error("none of the known-practices match the current practice");
  }

  atLastKnownPractice(): boolean {
    return (
      this.knownPractices[this.knownPractices.length - 1].id ===
      this.getCurrentPracticeID()
    );
  }

  atFirstPractice(): boolean {
    return this.currentPracticeIndex() === 0;
  }

  appendPractice(p: Practice) {
    this.knownPractices.push(p);
  }

  setCurrentPracticeID(id: number) {
    // verify that this practice exists and then set it
    this.currentPracticeID = this.getPracticeByID(id).id;

    if (
      this.knownPractices[this.currentPracticeIndex()].userSeenAt === undefined
    ) {
      this.knownPractices[this.currentPracticeIndex()].userSeenAt = new Date();
    }
  }

  hasMorePractices(): boolean {
    return this.totalQuestions > this.knownPractices.length;
  }

  setUserSelectedAnswerIndicesOnCurrentPractice(indices?: number[]) {
    var p = this.getCurrentPractice();
    if (
      p.practiceableType === "App\\Models\\Question" &&
      p.userResponseText !== undefined
    ) {
      throw new Error("userResponseText must not be set");
    }

    p.answerSubmitted = false;
    p.userSelectedAnswerIndices = indices;
  }

  setUserResponseTextOnCurrentPractice(v?: string) {
    var p = this.getCurrentPractice();

    if (p.practiceableType !== "App\\Models\\Question") {
      throw new Error("practice-type not supported: " + JSON.stringify(p));
    }

    if (
      p.userSelectedAnswerIndices !== undefined ||
      p.userResponseFileId !== undefined
    ) {
      throw new Error(
        "userSelectedAnswerIndices & userResponseFileId must not be set"
      );
    }

    p.answerSubmitted = false;
    p.userResponseText = v;
  }

  setUserResponseFileIDOnCurrentPractice(id?: number) {
    var p = this.getCurrentPractice();

    if (p.practiceableType !== "App\\Models\\Question") {
      throw new Error("practice-type not supported: " + JSON.stringify(p));
    }
    if (
      p.userSelectedAnswerIndices !== undefined ||
      p.userResponseText !== undefined
    ) {
      throw new Error(
        "userSelectedAnswerIndices & userResponseText must not be set"
      );
    }

    p.answerSubmitted = false;
    p.userResponseFileId = id;
  }

  isLiveExam(): boolean {
    return this.practiceTemplate?.isLiveExam() === true;
  }

  canCreateNewPracticeFrom(): boolean {
    return this.practiceTemplate?.canCreateNewPracticeFrom() !== false;
  }

  canSkipCurrentPractice(): boolean {
    return (
      this.practiceTemplate !== undefined &&
      this.getCurrentPractice().practiceableType === "App\\Models\\Question"
    );
  }

  isAnswerSelectedAtCurrentPractice(): boolean {
    const p = this.getCurrentPractice();

    switch (p.practiceableType) {
      case "App\\Models\\Question":
        switch (p.question.pattern.typ) {
          case QuestionPatternTypes.MCQ:
            return (
              p.userSelectedAnswerIndices !== undefined &&
              p.userSelectedAnswerIndices.length > 0
            );
          case QuestionPatternTypes.Essay:
          case QuestionPatternTypes.OneWord:
            return (
              p.userResponseText !== undefined &&
              p.userResponseText.trim().length > 0
            );

          case QuestionPatternTypes.MOSC:
          case QuestionPatternTypes.YN:
            return (
              p.userSelectedAnswerIndices !== undefined &&
              p.userSelectedAnswerIndices.length === 1
            );
          case QuestionPatternTypes.AudioResponse:
            return (
              p.userResponseFileId !== undefined && p.userResponseFileId !== 0
            );
          default:
            throw new Error(
              "unexpected question practice pattern: " + p.question.pattern.typ
            );
        }

      case "App\\Models\\DictionaryWord":
        switch (p.wordQuestion.pattern) {
          case WordQuestionType.Prompt:
            return (
              p.userSelectedAnswerIndices !== undefined &&
              p.userSelectedAnswerIndices.length === 1 &&
              (p.userSelectedAnswerIndices[0] === 1 ||
                p.userSelectedAnswerIndices[0] === 2)
            );
          case WordQuestionType.MultiMeaning:
          case WordQuestionType.ReverseMeaning:
            return (
              p.userSelectedAnswerIndices !== undefined &&
              p.userSelectedAnswerIndices.length > 0
            );
          default:
            throw new Error(
              "unexpected dictionary-word practice pattern: " +
                p.wordQuestion.pattern
            );
        }

      default:
        throw new Error("unexpected practice-type of current practice");
    }
  }
}

export interface ScheduleGroup {
  time: string;
  sessions: Session[];
}

export interface Session {
  id: number;
  timeStart: string;
  timeEnd: string;
  name: string;
  location: string;
  description: string;
  speakerNames: string[];
  tracks: string[];
}
