import React from "react";
import { IonButton, IonText } from "@ionic/react";
import {
  LearningMode,
  Practice,
  PracticeSession,
} from "../../models/PracticeSession";
import {
  PapiError,
  PapiPracticeSkipPOSTRequest,
} from "../../models/PracticeSession";
import { InitializeNextPracticeOfPracticeSessionByID } from "../../papiclient/client";
import "./PracticeSession.css";

interface SkipButtonProps {
  pSession: PracticeSession;
  setPracticeSession: (ps: PracticeSession) => void;
  setShowReviewPage: (value: boolean) => void;
}

export interface AnswerSubmissionRequestStatus {
  submitting: boolean;
  error: PapiError | null;
}

const SkipButton: React.FC<SkipButtonProps> = ({
  pSession,
  setPracticeSession,
  setShowReviewPage,
}) => {
  const [
    requestStatus,
    setRequestStatus,
  ] = React.useState<AnswerSubmissionRequestStatus>({
    submitting: false,
    error: null,
  });
  const practice = pSession.getCurrentPractice();

  if (practice.practiceableType !== "App\\Models\\Question") {
    throw new Error("practice-type not supported: " + JSON.stringify(practice));
  }

  const handleSkipButtonClick = () => {
    // Handle skip button click event
    setRequestStatus({ submitting: true, error: null });

    if (practice.userSeenAt === undefined) {
      // Handle the case where userSeenAt is undefined
      // You can add appropriate error handling or return early
      console.error("userSeenAt is undefined");
      const error: PapiError = {
        name: "PapiError",
        message: "userSeenAt is undefined",
        code: 404, // Replace with the actual error code
      };
      setRequestStatus({
        submitting: false,
        error: error,
      });
      return;
    }

    const req = {
      responseTime: Date.now() - practice.userSeenAt.valueOf(), // milliseconds
    } as PapiPracticeSkipPOSTRequest;

    const uri = `/papi/institution/practice-session/${pSession.id}/practice/${practice.id}/skip`;

    fetch(uri, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(req),
    }).then((resp: Response) => {
      if (!resp.ok) {
        return resp.json().then((err: PapiError) => {
          setRequestStatus({
            submitting: false,
            error: err,
          });
        });
      }

      var clone = pSession.clone();

      var tmpPractice = clone.getCurrentPractice();
      if (tmpPractice.practiceableType !== "App\\Models\\Question") {
        throw new Error(
          "practice-type not supported: " + JSON.stringify(tmpPractice)
        );
      }

      tmpPractice.userSkipped = true;
      if (
        isPracticeIDMatching(pSession.knownPractices, currentPracticeID + 1)
      ) {
        clone.changeToNextPractice();
        setPracticeSession(clone);
        return;
      } else {
        handleNextButtonClick();
      }

      if (tmpPractice.sequence >= pSession.totalQuestions) {
        setShowReviewPage(true);
      }
      setRequestStatus({
        submitting: false,
        error: null,
      });
    });
  };

  function isPracticeIDMatching(
    array: Practice[],
    currentPracticeID: number
  ): boolean {
    return array.some((item) => item.id === currentPracticeID);
  }

  const currentPracticeID: number = pSession.getCurrentPracticeID();

  const handleNextButtonClick = () => {
    setRequestStatus({ submitting: true, error: null });

    InitializeNextPracticeOfPracticeSessionByID(pSession.id)
      .then(async (newPractice: Practice) => {
        var clone = pSession.clone();
        clone.appendPractice(newPractice);
        clone.changeToNextPractice();
        setPracticeSession(clone);

        setRequestStatus({
          submitting: false,
          error: null,
        });
      })
      .catch(async (err: PapiError) => {
        setRequestStatus({
          submitting: false,
          error: err,
        });
      });
  };

  const answerSubmitted = practice.answerSubmitted;

  return (
    <>
      {(
        <IonButton
          className="button-text-normal"
          fill="solid"
          color="medium"
          slot="end"
          id="skipButton"
          onClick={handleSkipButtonClick}
          disabled={
            requestStatus.submitting &&
            pSession.learningMode !== LearningMode.PracticeTemplate
          }
        >
          {practice?.userSkipped ? "Skip Again!" : "Skip"}
        </IonButton>
      )}
      {requestStatus.error !== null ? (
        <IonText>{requestStatus.error.message}</IonText>
      ) : null}
    </>
  );
};
export default SkipButton;
