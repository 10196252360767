import React, { useState } from "react";
import {
  PracticeSession,
  QuestionPatternTypes,
  LearningMode,
  QuestionPractice,
} from "../../models/PracticeSession";
import {
  IonLabel,
  IonList,
  IonItem,
  IonRadio,
  IonRadioGroup,
  IonCheckbox,
  RadioGroupChangeEventDetail,
  CheckboxChangeEventDetail,
  IonBadge,
} from "@ionic/react";
import RichTextEditor from "../rte/Editor";
import { PracticeSessionAudioRecorder } from "./AudioRecorder";
import OneWordAnswerInput from "./OneWordAnswerInput";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
const QUESTION_MODEL = "App\\Models\\Question";

interface AnswerOptionsProps {
  pSession: PracticeSession;
  setPracticeSession: (ps: PracticeSession) => void;
  textInput: string;
  onTextInputChange: (value: string) => void;
}

const AnswerOptions: React.FC<AnswerOptionsProps> = ({
  pSession,
  setPracticeSession,
  textInput,
  onTextInputChange,
}) => {
  const practice = pSession.getCurrentPractice();
  let answerInput = null;

  if (practice.practiceableType !== QUESTION_MODEL) {
    throw new Error("practice-type not supported: " + JSON.stringify(practice));
  }
  const answerSubmitted = practice.answerSubmitted;

  function isUserResponseMatchingOptions(
    inputString: string,
    array: { id: number; text: string }[]
  ): boolean {
    return array.some(
      (item) =>
        item.text.trim().toLowerCase() === inputString.trim().toLowerCase()
    );
  }

  switch (practice.question.pattern.typ) {
    case QuestionPatternTypes.OneWord:
      answerInput = (
        <OneWordAnswerInput
          practice={practice}
          pSession={pSession}
          onTextInputChange={onTextInputChange}
          answerSubmitted={answerSubmitted}
        />
      );
      break;
    case QuestionPatternTypes.Essay:
      answerInput = renderEssayAnswerInput(
        practice,
        pSession,
        setPracticeSession,
        answerSubmitted
      );
      break;
    case QuestionPatternTypes.AudioResponse:
      answerInput = renderAudioResponseInput(
        practice,
        pSession,
        setPracticeSession
      );
      break;
    case QuestionPatternTypes.MOSC:
    case QuestionPatternTypes.YN:
      answerInput = renderRadioGroup(
        practice,
        pSession,
        setPracticeSession,
        answerSubmitted
      );
      break;
    case QuestionPatternTypes.MCQ:
      answerInput = renderCheckboxGroup(
        practice,
        pSession,
        setPracticeSession,
        answerSubmitted
      );
      break;
    default:
      throw new Error(
        "unsupported question pattern: " + practice.question.pattern.name()
      );
  }
  interface Option {
    id: number;
    text: string;
    key: number;
  }

  function areAllAnswerIdsSelected(
    answerIds: number[],
    selectedIds: number[]
  ): boolean {
    if (answerIds.length !== selectedIds.length) {
      return false;
    }
    return answerIds.every((answerId) => selectedIds.includes(answerId));
  }

  function getSelectedIds(
    options: Option[],
    userResponseIndices: number[]
  ): number[] {
    const selectedIds: number[] = [];

    for (const index of userResponseIndices) {
      const option = options.find((option) => option.key === index);
      if (option) {
        selectedIds.push(option.id);
      }
    }

    return selectedIds;
  }

  const userResponseText: string = practice.userResponseText || "";

  if (practice.question.options) {
    const isMatch = isUserResponseMatchingOptions(
      userResponseText,
      practice.question.options
    );

    const optionsWithIndex: Option[] = practice.question.options.map(
      (option, index) => {
        return {
          ...option,
          key: index + 1,
        };
      }
    );

    const userResponseIndices: number[] =
      practice.userSelectedAnswerIndices || [];
    const answersArray: number[] = (practice.question.answer || []).map(Number);

    const selectedIds: number[] = getSelectedIds(
      optionsWithIndex,
      userResponseIndices
    );

    const allAnswerIdsSelected: boolean = areAllAnswerIdsSelected(
      answersArray,
      selectedIds
    );

    return (
      <>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <strong>({practice.question.pattern.helpText})</strong>
          {pSession.learningMode !== LearningMode.PracticeTemplate && (
            <>
              {practice.question.pattern.typ === "One Word" &&
                answerSubmitted &&
                (isMatch ? (
                  <IonBadge color="success" slot="end">
                    Your answer is correct!
                  </IonBadge>
                ) : (
                  <IonBadge color="danger" slot="end">
                    Your answer is incorrect!
                  </IonBadge>
                ))}

              {[
                "Multiple Option Multiple Choice",
                "Multiple Option Single Choice",
                "Yes/No",
              ].includes(practice.question.pattern.typ) &&
                answerSubmitted && (
                  <IonBadge
                    color={allAnswerIdsSelected ? "success" : "danger"}
                    slot="end"
                  >
                    Your answer is{" "}
                    {allAnswerIdsSelected ? "correct" : "incorrect"}!
                  </IonBadge>
                )}
            </>
          )}
        </div>

        <br />
        {answerInput}
      </>
    );
  } else {
    return (
      <>
        <strong>({practice.question.pattern.helpText})</strong>
        <br />
        {answerInput}
      </>
    );
  }
};

// Modular functions for rendering different types of answer inputs

function renderEssayAnswerInput(
  practice: QuestionPractice,
  pSession: PracticeSession,
  setPracticeSession: (ps: PracticeSession) => void,
  answerSubmitted: boolean
) {
  return (
    <ReactQuill
      onChange={(v: string) => {
        if(v!="<p><br></p>"){
        var clone = pSession.clone();
        var p = pSession.getCurrentPractice();
        p.userResponseFileId = undefined;
        p.userSelectedAnswerIndices = undefined;
        clone.setUserResponseTextOnCurrentPractice(v);
        setPracticeSession(clone);
        }
      }}
      placeholder="Write your essay response here ..."
      value={practice.userResponseText}
      id="quillEditor"
      readOnly={answerSubmitted && pSession.learningMode !== LearningMode.PracticeTemplate}
      />
  );
}

function renderAudioResponseInput(
  practice: QuestionPractice,
  pSession: PracticeSession,
  setPracticeSession: (ps: PracticeSession) => void
) {
  return (
    <PracticeSessionAudioRecorder
      url={
        practice.userResponseFileId === undefined
          ? undefined
          : `/papi/user/file/${practice.userResponseFileId}/1`
      }
      onChange={(id?: number) => {
        var clone = pSession.clone();
        clone.setUserResponseFileIDOnCurrentPractice(id);
        setPracticeSession(clone);
      }}
    />
  );
}

function renderRadioGroup(
  practice: QuestionPractice,
  pSession: PracticeSession,
  setPracticeSession: (ps: PracticeSession) => void,
  answerSubmitted: boolean
) {
  if (practice.question.options === undefined) {
    throw new Error("question options are not supplied");
  }
  function isOptionIdInAnswerArray(arr: string[], num: number): boolean {
    return arr.some((item) => parseInt(item) === num);
  }
  return (
    <IonList>
      <IonRadioGroup
        onIonChange={(ev: CustomEvent<RadioGroupChangeEventDetail<number>>) => {
          const selectedValue = ev.detail.value; // Get the selected value

          const clone = pSession.clone();
          clone.setUserSelectedAnswerIndicesOnCurrentPractice([
            selectedValue + 1,
          ]);

          setPracticeSession(clone);
        }}
      >
        {practice.question.options.map((option, index) => (
          <IonItem key={`option-${index}`}>
            <IonRadio
              name="option_radio"
              slot="start"
              value={index}
              disabled={answerSubmitted && pSession.learningMode !== LearningMode.PracticeTemplate}
              ></IonRadio>
            <IonLabel
              style={
                pSession.learningMode !== LearningMode.PracticeTemplate &&
                answerSubmitted
                  ? isOptionIdInAnswerArray(practice.question.answer, option.id)
                    ? { color: "green" }
                    : { color: "red" }
                  : {}
              }
            >
              {option.text}
            </IonLabel>
          </IonItem>
        ))}
      </IonRadioGroup>
    </IonList>
  );
}

function renderCheckboxGroup(
  practice: QuestionPractice,
  pSession: PracticeSession,
  setPracticeSession: (ps: PracticeSession) => void,
  answerSubmitted: boolean
) {
  if (!practice.question.options) {
    throw new Error("Question options are not supplied");
  }

  function isOptionIdInAnswerArray(arr: string[], num: number): boolean {
    return arr.some((item) => parseInt(item) === num);
  }

  return (
    <IonList>
      {practice.question.options.map((option, index) => (
        <IonItem key={index + 1}>
          <IonCheckbox
            name="option_checkbox"
            slot="start"
            disabled={answerSubmitted && pSession.learningMode !== LearningMode.PracticeTemplate}
            onIonChange={(_: CustomEvent<CheckboxChangeEventDetail>) => {
              const clone = pSession.clone();
              const existingIndices =
                clone.getCurrentPractice().userSelectedAnswerIndices || [];

              const isChecked = existingIndices.includes(index + 1);

              const newIndices = isChecked
                ? existingIndices.filter((idx) => idx !== index + 1)
                : [...existingIndices, index + 1];

              clone.setUserSelectedAnswerIndicesOnCurrentPractice(newIndices);

              setPracticeSession(clone);
            }}
            checked={(
              pSession.getCurrentPractice().userSelectedAnswerIndices || []
            ).includes(index + 1)}
          ></IonCheckbox>
          <IonLabel
            style={
              pSession.learningMode !== LearningMode.PracticeTemplate &&
              answerSubmitted
                ? isOptionIdInAnswerArray(practice.question.answer, option.id)
                  ? { color: "green" }
                  : { color: "red" }
                : {}
            }
          >
            {option.text}
          </IonLabel>
        </IonItem>
      ))}
    </IonList>
  );
}

export default AnswerOptions;
