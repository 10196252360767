import React from "react";
import { IonGrid, IonRow, IonCol, IonRouterLink } from "@ionic/react";

interface Topic {
  id: string;
  name: string;
  description: string;
}

interface TopicListProps {
  topics: Topic[];
}

const TopicList: React.FC<TopicListProps> = ({ topics }) => (
  <IonGrid className="custom-table">
    {/* Column Headings */}

    <IonRow className="column-heading">
      <IonCol size="auto">#</IonCol>
      <IonCol>Name</IonCol>
      <IonCol>Description</IonCol>
    </IonRow>

    {/* Data Rows */}
    {topics.map((topic, index) => (
      <IonRow
        key={topic.id}
        className={`data-row ${index === topics.length - 1 ? "last-row" : ""}`}
      >
        <IonCol size="auto">{index + 1}</IonCol>
        <IonCol>
          <IonRouterLink
            routerLink={`/StudyMaterialReadOnlyList/${topic.id}`}
            style={{ color: "#337ab7", textDecoration: "none" }}
            className="ion-router-link"
          >
            {topic.name}
          </IonRouterLink>
        </IonCol>
        <IonCol style={{color:"#797979"}}>{topic.description}</IonCol>
      </IonRow>
    ))}
  </IonGrid>
);

export default TopicList;
