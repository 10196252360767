export const routes = {
  appStudentMenu: [
    {
      title: "Super Admin Dashboard",
      path: "/SuperAdmin/Dashboard",
      icon: "fa fa-university",
      isLaravelPage: true,
    },
    {
      title: "Search Institutions",
      path: "/institution/search",
      icon: "fa fa-search",
      isLaravelPage: true,
    },
    {
      title: "Study Groups",
      path: "/StudyGroups",
      icon: "fa fa-users",
      isLaravelPage: true,
    },
    {
      title: "Explore More ...",
      path: "/ui/OpenDashboard",
      icon: "fa fa-globe",
      isLaravelPage: true,
    },
    {
      title: "My Dashboard",
      path: "/StudentDashboard",
      icon: "fa fa-home",
      isLaravelPage: true,
    },
    {
      title: "Available Courses",
      path: "/CourseStructure/Student/List",
      icon: "fa fa-desktop",
      isLaravelPage: true,
    },
    {
      title: "My Practice",
      path: "/PracticeSession/List",
      icon: "fa fa-check-square",
    },
    {
      title: "Practice Templates",
      path: "/PracticeTemplates/Student/List",
      icon: "fa fa-check-square",
      isLaravelPage: true,
    },
    {
      title: "Assignments",
      path: "/Assignment/Student/List",
      icon: "fa fa-book",
      isLaravelPage: true,
    },
    {
      title: "Study Materials & Topics",
      path: "/Topic/TopicReadOnly",
      icon: "fa fa-tags",
      isLaravelPage: true,
    },
    {
      title: "My Dictionary",
      path: "/StudentsDictionary/WordsSearchFromStudentsDictionary",
      icon: "fa fa-book",
      isLaravelPage: true,
    },
    {
      title: "My Subscriptions",
      path: "/StudentSubscription/getStudentSubscribedList",
      icon: "fa fa-user",
      isLaravelPage: true,
    },
    {
      title: "Institutions List for Subscription",
      path: "/StudentSubscription/InstitutionListForStudentSubscriptions",
      icon: "fa fa-institution",
      isLaravelPage: true,
    },
  ],
  // loggedInPages: [
  //   { title: "Account", path: "/account", icon: person },
  //   { title: "Logout", path: "/logout", icon: logOut },
  // ],
  // loggedOutPages: [
  //   { title: "Login", path: "/login", icon: logIn },
  //   { title: "Signup", path: "/signup", icon: personAdd },
  // ],
  appInstructorMenu: [
    {
      title: "Super Admin Dashboard",
      path: "/SuperAdmin/Dashboard",
      icon: "fa fa-university",
      isLaravelPage: true,
    },
    {
      title: "Search Institutions",
      icon: "fa fa-search",
      path: "/institution/search",
      isLaravelPage: true,
    },
    {
      title: "Study Groups",
      icon: "fa fa-users",
      path: "/StudyGroups",
      isLaravelPage: true,
    },
    {
      title: "Explore More...",
      icon: "fa fa-globe",
      path: "/ui/OpenDashboard",
      isLaravelPage: false,
    },
    {
      title: "My Dashboard",
      icon: "fa fa-home",
      path: "/InstructorDashboard",
      isLaravelPage: true,
    },
    {
      title: "Question Bank",
      icon: "fa fa-question-circle",
      path: "/QuestionAnswer/ListOfQuestions",
      isLaravelPage: true,
    },
    {
      title: "Study Materials & Topics",
      icon: "fa fa-tags",
      path: "/Topic/ListOfTopics",
      isLaravelPage: true,
    },
    {
      title: "Exam Bank",
      icon: "fa fa-book",
      path: "/exams",
      isLaravelPage: true,
    },
    {
      title: "My Wordlists",
      icon: "fa fa-file-word",
      path: "/Wordlist/SearchByWordlist",
      isLaravelPage: true,
    },
    {
      title: "My Dictionary",
      icon: "fa fa-book",
      path: "/Wordlist/SearchWordsFromDictionary",
      isLaravelPage: true,
    },
    {
      title: "Practice Templates",
      icon: "fa fa-check-square",
      path: "/ui/PracticeTemplates/List",
      isLaravelPage: true,
    },
    {
      title: "Assignments",
      icon: "fa fa-book",
      path: "/Assignment/List",
      isLaravelPage: true,
    },
    {
      title: "Practice Evaluations",
      icon: "fas fa-tasks",
      path: "/PracticeSession/EvaluationRequests",
      isLaravelPage: true,
    },
    {
      title: "Assignment Evaluations",
      icon: "fa fa-calendar",
      path: "/Assignment/Evaluation",
      isLaravelPage: true,
    },
    {
      title: "My Institutions",
      icon: "fa fa-university",
      path: "ListOfInstitution",
      isLaravelPage: true,
    },
    {
      title: "Course Structures",
      icon: "fa fa-desktop",
      path: "/CourseStructure/List",
      isLaravelPage: true,
    },
    {
      title: "Students",
      icon: "fa fa-users",
      path: "/StudentsView",
      isLaravelPage: true,
    },
  ],

  UserNotLoggedInMenu: [
    {
      title: "Explore More...",
      icon: "fa fa-globe",
      path: "/ui/OpenDashboard",
      isLaravelPage: false,
    },
  ],

  UserNotLoggedInButRoleNotSelectedMenu: [
    {
      title: "Search Institutions",
      icon: "fa fa-search",
      path: "/institution/search",
      isLaravelPage: true,
    },
    {
      title: "Study Groups",
      icon: "fa fa-users",
      path: "/StudyGroups",
      isLaravelPage: true,
    },
    {
      title: "Explore More...",
      icon: "fa fa-globe",
      path: "/ui/OpenDashboard",
      isLaravelPage: false,
    },
  ],
};
