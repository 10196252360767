import React, { createContext, useState, useEffect, useContext } from "react";

interface User {
  name: string;
  institutionName: string;
  institutionId: number;
  role: string;
  emailId: string;
  profileImage: string;
  isSuperAdmin: boolean;
}

interface UserContextProps {
  user: User | null;
  loading: boolean;
}

const UserContext = createContext<UserContextProps | undefined>(undefined);

const UserProvider: React.FC = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const apiUrl = "/papi/institution/user";

    fetch(apiUrl)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok.");
        }
        return response.json();
      })
      .then((responseData: User) => {
        setUser(responseData);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.error("Error while fetching data from the API.", err);
      });
  }, []);

  return (
    <UserContext.Provider value={{ user, loading }}>
      {children}
    </UserContext.Provider>
  );
};

const useUser = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error("useUser must be used within a UserProvider");
  }
  return context;
};

export { UserProvider, useUser };
