import React from "react";
import {
  PracticeSession,
  WordQuestionType,
} from "../../models/PracticeSession";
import {
  IonLabel,
  IonItem,
  IonRadio,
  IonRadioGroup,
  IonList,
  RadioGroupChangeEventDetail,
  IonBadge,
  IonText,
  IonIcon,
} from "@ionic/react";
import { thumbsDownOutline, thumbsUpOutline } from "ionicons/icons";
import { dangerWords, successWords } from "./feedbackWords";
import "./DictionaryWordAnswerOptions.css";

interface DictionaryWordAnswerOptionsProps {
  pSession: PracticeSession;
  setPracticeSession: (ps: PracticeSession) => void;
}

export const DictionaryWordAnswerOptions: React.FC<DictionaryWordAnswerOptionsProps> = ({
  pSession,
  setPracticeSession,
}) => {
  const practice = pSession.getCurrentPractice();
  let answerRadioItems = null;

  if (practice.practiceableType !== "App\\Models\\DictionaryWord") {
    throw new Error("practice-type not supported: " + JSON.stringify(practice));
  }
  const answerSubmitted = practice.answerSubmitted;

  switch (practice.wordQuestion.pattern) {
    case WordQuestionType.Prompt:
      if (practice.wordQuestion.options !== undefined) {
        throw new Error(
          "dictionary word options are supplied but not required"
        );
      }

      answerRadioItems = (
        <>
          <IonItem>
            <IonRadio
              slot="start"
              value={1}
              disabled={practice.answerSubmitted}
              color={"success"}
            ></IonRadio>
            <IonLabel>Yes</IonLabel>
          </IonItem>
          <IonItem>
            <IonRadio
              slot="start"
              value={2}
              disabled={practice.answerSubmitted}
              color={"danger"}
            ></IonRadio>
            <IonLabel>No</IonLabel>
          </IonItem>
        </>
      );
      break;
    case WordQuestionType.MultiMeaning:
    case WordQuestionType.ReverseMeaning:
      if (practice.wordQuestion.options === undefined) {
        throw new Error(
          "dictionary word options are NOT supplied but are required"
        );
      }
      answerRadioItems = practice.wordQuestion.options.map((option, index) => {
        const isCorrectAnswer =
          option.text.trim().toLowerCase() ===
            practice.wordQuestion.word.meaning.trim().toLowerCase() ||
          option.text.trim().toLowerCase() ===
            practice.wordQuestion.word.name.trim().toLowerCase();

        return (
          <IonItem key={`option-${index}`}>
            <IonRadio
              slot="start"
              value={index + 1}
              disabled={practice.answerSubmitted}
              color={
                answerSubmitted ? (isCorrectAnswer ? "success" : "danger") : ""
              }
            ></IonRadio>
            <IonLabel
              color={
                answerSubmitted ? (isCorrectAnswer ? "success" : "danger") : ""
              }
            >
              {option.text}
            </IonLabel>
          </IonItem>
        );
      });
      break;
    default:
      throw new Error(
        "unsupported dictionary word type: " + practice.wordQuestion.pattern
      );
  }
  type OptionItem = {
    key: number;
    text: string;
  };

  function extractTextOfUserSelectedIndexFromOptionsArray(
    optionsArrayWithIndex: OptionItem[],
    userSelectedAnswerIndices: number[]
  ): string {
    return userSelectedAnswerIndices
      .map((index) => {
        const matchedOption = optionsArrayWithIndex.find(
          (option) => option.key === index
        );
        return matchedOption ? matchedOption.text : "";
      })
      .join(" ");
  }

  const optionsArrayWithIndex =
    practice?.wordQuestion?.options?.map((item, index) => ({
      key: index + 1,
      text: item.text,
    })) || [];

  const userSelectedIndex: number[] = practice.userSelectedAnswerIndices || [];

  const selectedTexts = extractTextOfUserSelectedIndexFromOptionsArray(
    optionsArrayWithIndex,
    userSelectedIndex
  );

  function compareStrings(str1: string, str2: string): boolean {
    const trimmedStr1 = str1.trim();
    const trimmedStr2 = str2.trim();

    const lowerCaseStr1 = trimmedStr1.toLowerCase();
    const lowerCaseStr2 = trimmedStr2.toLowerCase();

    return lowerCaseStr1 === lowerCaseStr2;
  }

  const isMatch = compareStrings(
    selectedTexts,
    practice.wordQuestion.pattern === WordQuestionType.MultiMeaning
      ? practice.wordQuestion.word.meaning
      : practice.wordQuestion.pattern === WordQuestionType.ReverseMeaning
      ? practice.wordQuestion.word.name
      : ""
  );

  const patternIsNotPrompt = practice.wordQuestion.pattern !== "prompt";

  const getRandomWord = (words: string[]) => {
    const randomIndex = Math.floor(Math.random() * words.length);
    return words[randomIndex];
  };

  const getRandomWordForQuestion = (questionId: string, isSuccess: boolean) => {
    const words = isSuccess ? successWords : dangerWords;

    const storedRandomWord = localStorage.getItem(`randomWord_${questionId}`);

    if (!storedRandomWord) {
      const newRandomWord = getRandomWord(words);

      localStorage.setItem(`randomWord_${questionId}`, newRandomWord);

      return newRandomWord;
    }

    return storedRandomWord;
  };

  return (
    <IonList>
      <div id="wordId" data-word-id={practice.wordQuestion.word.id}></div>
      <div
        id="wordMeaning"
        data-word-meaning={practice.wordQuestion.word.meaning}
      ></div>
      <div id="wordName" data-word-name={practice.wordQuestion.word.name}></div>
      {answerSubmitted && patternIsNotPrompt && (
        <div
          className="ion-flex ion-justify-content-end d-flex"
        >
          <IonBadge color={isMatch ? "success" : "danger"}>
            Your answer is {isMatch ? "correct" : "incorrect"}!
          </IonBadge>
        </div>
      )}

      {answerSubmitted && !patternIsNotPrompt && (
        <div className="ion-flex ion-justify-content-end d-flex">
          <IonBadge
            color={userSelectedIndex[0] === 1 ? "success" : "danger"}
            slot="end"
            className="answer-feedback"
          >
            <IonIcon
              icon={
                userSelectedIndex[0] === 1 ? thumbsUpOutline : thumbsDownOutline
              }
              size="small"
              className="mr-5"
            />
            {getRandomWordForQuestion(
              String(practice.wordQuestion.word.id),
              userSelectedIndex[0] === 1
            )}
          </IonBadge>
        </div>
      )}

      <IonRadioGroup
        key={`practice-${practice.id}-dictionary-word`}
        onIonChange={(ev: CustomEvent<RadioGroupChangeEventDetail<number>>) => {
          // skip the update if the value has not changed or if the answer has
          // already been submitted
          if (
            practice.answerSubmitted ||
            (practice.userSelectedAnswerIndices !== undefined &&
              practice.userSelectedAnswerIndices.length === 1 &&
              practice.userSelectedAnswerIndices[0] === ev.detail.value)
          ) {
            ev.preventDefault();
            return;
          }

          var clone = pSession.clone();
          clone.setUserSelectedAnswerIndicesOnCurrentPractice([
            ev.detail.value,
          ]);

          setPracticeSession(clone);
        }}
        value={
          practice.userSelectedAnswerIndices !== undefined
            ? practice.userSelectedAnswerIndices[0]
            : null
        }
      >
        {answerRadioItems}
      </IonRadioGroup>

      {answerSubmitted && (
        <div className="ion-padding">
          <IonLabel className="labelStyle">
            Correct Answer:{" "}
            <IonText className="textStyle">
              {practice.wordQuestion.word.meaning}
            </IonText>
          </IonLabel>
        </div>
      )}
    </IonList>
  );
};
