import React from "react";
import { IonText } from "@ionic/react";
import SectionHeading from "../SectionHeading/SectionHeading";

interface TextSectionProps {
  title: string;
  content: string | string[];
  headingClassName?: string;
  contentClassName?: string;
}

const TextSection: React.FC<TextSectionProps> = ({
  title,
  content,
  headingClassName,
  contentClassName,
}) => {
  // Convert content to an array if it's a string
  const contentArray =
    typeof content === "string" ? content.split("\n").filter(Boolean) : content;

  return (
    <div>
      <IonText color="primary">
        <SectionHeading
          heading={title}
          className={headingClassName || "text-left my-2"}
        />
      </IonText>
      <IonText className={contentClassName || "text-left my-2"}>
        {contentArray.map((point, index) => (
          <p key={index} className="mb-4">
            {point}
          </p>
        ))}
      </IonText>
    </div>
  );
};

export default TextSection;
