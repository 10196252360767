// ContactUs.tsx
import React from "react";
import { IonGrid, IonRow, IonCol, IonText } from "@ionic/react";
import { locationOutline, callOutline, mailOutline } from "ionicons/icons";
import FeatureCard from "../FeatureSection/FeatureCard";
import SectionHeading from "../SectionHeading/SectionHeading";

const ContactUs: React.FC = () => (
  <div className="h-full w-full my-12 mx-0">
    <SectionHeading className="text-center" heading="Contact Us" />

    <IonGrid>
      <IonRow>
        <IonCol size="12" size-md="4">
          <FeatureCard
            icon={locationOutline}
            title="Address"
            description={[
              "32-B, Rishikesh Vihar, Near SBI,",
              "Bagh Mugalia,",
              "Bhopal - 462043,",
              "Madhya Pradesh, India",
            ]}
          />
        </IonCol>
        <IonCol size="12" size-md="4">
          <FeatureCard
            icon={callOutline}
            title="Phone"
            description={["India +91 89599 01904", "USA +1 (206) 747-8828"]}
          />
        </IonCol>
        <IonCol size="12" size-md="4">
          <FeatureCard
            icon={mailOutline}
            title="Email"
            description="support@zerra.in"
          />
        </IonCol>
      </IonRow>
    </IonGrid>
  </div>
);

export default ContactUs;
