import React from "react";
import classNames from "classnames";

interface SectionHeadingProps {
  heading: string;
  className?: string;
}

const SectionHeading: React.FC<SectionHeadingProps> = ({
  heading,
  className,
}) => {
  return (
    <h2
      className={classNames(
        "uppercase font-thin  text-gray-700 mb-5 text-3xl relative leading-loose",
        className
      )}
    >
      {heading}
    </h2>
  );
};

export default SectionHeading;
