import React from "react";
import { IonButton, IonText } from "@ionic/react";
import {
  LearningMode,
  Practice,
  PracticeSession,
  QuestionPatternTypes,
} from "../../models/PracticeSession";
import {
  PapiError,
  PapiPracticeAnswerSubmitPOSTRequest,
} from "../../models/PracticeSession";
import { InitializeNextPracticeOfPracticeSessionByID } from "../../papiclient/client";
import "./PracticeSession.css";

interface PracticeItem {
  practiceableType: string;
  id: number;
  isAdaptiveSelection: boolean;
  sequence: number;
  question: {
    id: number;
    summary: string;
    description: string;
    answerExplanation: string;
    answer: number[];
    pattern: {
      id: number;
      typ: string;
      helpText: string;
    };
    difficultyLevel: number;
    options: {
      id: number;
      text: string;
    }[];
  };
  userSelectedAnswerIndices?: number[];
  answerSubmitted: boolean;
  userSkipped: boolean;
  userSeenAt?: string;
}
interface SubmitButtonProps {
  pSession: PracticeSession;
  requestStatus: AnswerSubmissionRequestStatus;
  setRequestStatus: (req: AnswerSubmissionRequestStatus) => void;
  setPracticeSession: (ps: PracticeSession) => void;
  textInput?: string;
  setShowReviewPage?: (value: boolean) => void;
}

export interface AnswerSubmissionRequestStatus {
  submitting: boolean;
  error: PapiError | null;
}

const SubmitButton: React.FC<SubmitButtonProps> = ({
  pSession,
  requestStatus,
  setRequestStatus,
  setPracticeSession,
  textInput,
  setShowReviewPage,
}) => {
  const practice = pSession.getCurrentPractice();

  const handleSubmitButtonClick = () => {
    // Handle submit button click event
    if (practice.userSeenAt === undefined) {
      throw new Error(
        "practice has not been seen by the user before submission"
      );
    }

    setRequestStatus({ submitting: true, error: null });

    const req = {
      responseTime: Date.now() - practice.userSeenAt.valueOf(), // milliseconds
      studentResponseOptionIndices: practice.userSelectedAnswerIndices,
    } as PapiPracticeAnswerSubmitPOSTRequest;

    if (practice.practiceableType === "App\\Models\\Question") {
      req.studentResponseText = practice.userResponseText;
      req.studentResponseFileId = practice.userResponseFileId;
    }

    const uri = `/papi/institution/practice-session/${pSession.id}/practice/${practice.id}/answer`;

    fetch(uri, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(req),
    }).then((resp: Response) => {
      if (!resp.ok) {
        return resp.json().then((err: PapiError) => {
          setRequestStatus({
            submitting: false,
            error: err,
          });
        });
      }

      var clone = pSession.clone();

      var tmpPractice = clone.getCurrentPractice();
      tmpPractice.answerSubmitted = true;

      if (tmpPractice.practiceableType === "App\\Models\\Question") {
        tmpPractice.userSkipped = false;
      }

      setPracticeSession(clone);

      if (pSession.learningMode === LearningMode.PracticeTemplate) {
        if (
          isPracticeIDMatching(pSession.knownPractices, currentPracticeID + 1)
        ) {
          var clone = pSession.clone();
          clone.changeToNextPractice();
          setPracticeSession(clone);
          return;
        } else {
          handleNextButtonClick();
        }
        if (
          tmpPractice.sequence >= pSession.totalQuestions &&
          setShowReviewPage
        ) {
          setShowReviewPage(true);
        }
      }

      setRequestStatus({
        submitting: false,
        error: null,
      });
    });
  };

  function isPracticeIDMatching(
    array: Practice[],
    currentPracticeID: number
  ): boolean {
    return array.some((item) => item.id === currentPracticeID);
  }

  const currentPracticeID: number = pSession.getCurrentPracticeID();

  const handleNextButtonClick = () => {
    setRequestStatus({ submitting: true, error: null });

    InitializeNextPracticeOfPracticeSessionByID(pSession.id)
      .then(async (newPractice: Practice) => {
        var clone = pSession.clone();
        clone.appendPractice(newPractice);
        clone.changeToNextPractice();
        setPracticeSession(clone);

        setRequestStatus({
          submitting: false,
          error: null,
        });
      })
      .catch(async (err: PapiError) => {
        setRequestStatus({
          submitting: false,
          error: err,
        });
      });
  };

  const isDisabled =
    requestStatus.submitting ||
    practice.answerSubmitted ||
    !pSession.isAnswerSelectedAtCurrentPractice() ||
    (practice.practiceableType === "App\\Models\\Question" &&
      practice.question?.pattern.typ === QuestionPatternTypes.OneWord &&
      !(textInput?.length ?? 0));

  const isDisabledPracticeTemplate =
    practice.userResponseFileId === undefined &&
    (practice.userResponseText === undefined ||
      practice.userResponseText.trim() === "<p><br></p>") &&
    (practice.userSelectedAnswerIndices === undefined ||
      practice.userSelectedAnswerIndices.length === 0);

  return (
    <IonButton
      className="button-text-normal"
      fill="solid"
      color="success"
      slot="end"
      id="submit-answer"
      onClick={handleSubmitButtonClick}
      disabled={
        (isDisabled &&
          pSession.learningMode !== LearningMode.PracticeTemplate) ||
        (isDisabledPracticeTemplate &&
          pSession.learningMode === LearningMode.PracticeTemplate)
      }
    >
      {practice.answerSubmitted ? "Submitted!" : "Submit Answer"}
    </IonButton>
  );
};

export default SubmitButton;
