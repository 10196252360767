import { useEffect, useState } from "react";

interface Topic {
  id: string;
  name: string;
  description: string;
}

const useFetchTopics = (questionId: number, isOpen: boolean) => {
  const [topics, setTopics] = useState<Topic[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const fetchTopics = async () => {
      try {
        setLoading(true);
        const response = await fetch(`/papi/institution/question/${questionId}/topic`);
        const responseData: { topics: Topic[] } = await response.json();

        setTopics(responseData.topics);
      } catch (error) {
        console.error("Error fetching topics:", error);
      } finally {
        setLoading(false);
      }
    };

    if (isOpen) {
      fetchTopics();
    }
  }, [isOpen, questionId]);

  return { topics, loading };
};

export default useFetchTopics;
