import getBlobDuration from "get-blob-duration";

const audioType = "audio/*";
type tickCbSeconds = (seconds: number) => void;

export class AudioRecorder {
  private seconds: number;
  private chunks: BlobPart[];
  private tickCb: tickCbSeconds;

  private recordingTimer?: NodeJS.Timeout;
  private mediaRecorder?: MediaRecorder;

  constructor(tickCb: tickCbSeconds) {
    this.seconds = 0;
    this.chunks = [];
    this.tickCb = tickCb;
  }

  static onDataAvailable(chunks: BlobPart[], ev: BlobEvent) {
    if (ev.data && ev.data.size > 0) {
      chunks.push(ev.data);
    }
  }

  setRecorder(mediaRecorder?: MediaRecorder) {
    if (this.mediaRecorder !== undefined) {
      this.mediaRecorder.ondataavailable = null;
    }

    this.mediaRecorder = mediaRecorder;

    if (this.mediaRecorder !== undefined) {
      this.mediaRecorder.ondataavailable = (ev: BlobEvent) =>
        AudioRecorder.onDataAvailable(this.chunks, ev);
    }
  }

  clone(): AudioRecorder {
    var r = new AudioRecorder(this.tickCb);
    r.seconds = this.seconds;
    r.recordingTimer = this.recordingTimer;
    r.chunks = this.chunks.slice();
    r.setRecorder(this.mediaRecorder);

    // recorder ownership is now transferred to the clone
    this.mediaRecorder = undefined;

    return r;
  }

  startRecording() {
    if (this.chunks.length > 0) {
      this.reset();
    }

    if (this.mediaRecorder === undefined) {
      throw new Error("media recorder is not initialized yet");
    }

    // start recorder with 10ms buffer
    this.mediaRecorder.start(10);

    if (this.recordingTimer !== undefined) {
      throw new Error("will leak a timer if we start a new one");
    }

    this.recordingTimer = setInterval(
      (() => {
        this.seconds++;
        this.tickCb(this.seconds);
      }).bind(this),
      1000
    );
  }

  stopRecording() {
    if (this.recordingTimer === undefined) {
      throw new Error("recording has already stopped");
    }

    if (this.mediaRecorder === undefined) {
      throw new Error("media recorder is not initialized yet");
    }

    clearInterval(this.recordingTimer);
    this.recordingTimer = undefined;

    // stop the recorder
    this.mediaRecorder.stop();
  }

  reset() {
    if (this.isRecording()) {
      this.stopRecording();
    }

    this.seconds = 0;
    this.tickCb(0);
    this.recordingTimer = undefined;
    // wipe old data chunks
    this.chunks = [];
  }

  // convert saved chunks to blob
  getAudioBlob(): Blob {
    return new Blob(this.chunks, { type: audioType });
  }

  // generate audio url from blob
  getAudioUrl(): string {
    return window.URL.createObjectURL(this.getAudioBlob());
  }

  isRecording(): boolean {
    return this.recordingTimer !== undefined;
  }

  hasRecording(): boolean {
    return !this.isRecording() && this.chunks.length > 0;
  }

  isMediaAvailable(): boolean {
    return this.mediaRecorder !== undefined;
  }

  Seconds(): number {
    return this.seconds;
  }

  async setBlob(b: Blob) {
    this.seconds = await getBlobDuration(b).catch((err) => {
      console.log("failed to get blob duration: ", err);
      return Promise.reject(err);
    });
    this.chunks = [b];
  }
}
