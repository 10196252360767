import React, { useEffect, useState } from "react";
import UserProfileCard from "../../components/OpenDashboard/UserProfileCard";
import Statistics from "../../components/OpenDashboard/Statistics";
import SearchBar from "../../components/SearchBar/SearchBar";

interface Summary {
  InstitutionCount: number;
  InstructorCount: number;
  StudentCount: number;
  PracticeCount: number;
  QuestionCount: number;
}

interface UserProfile {
  id: number;
  firstName: string;
  lastName: string;
  aboutMe: string;
  email: string;
  skype: string;
  city: string;
  state: string;
  country: string;
}
interface UserSearchResponse {
  response: {
    userProfiles: UserProfile[];
  };
}

const ExploreMorePage: React.FC = () => {
  const [summary, setSummary] = useState<Summary>({
    InstitutionCount: 0,
    InstructorCount: 0,
    StudentCount: 0,
    PracticeCount: 0,
    QuestionCount: 0,
  });
  const [searchQuery, setSearchQuery] = useState("");
  const [userList, setUserList] = useState<UserProfile[]>([]);
  const [searchError, setSearchError] = useState("");

  useEffect(() => {
    const fetchSummary = async () => {
      try {
        const response = await fetch(`/papi/open-dashboard/summary`);
        const data: Summary = await response.json();
        setSummary(data);
      } catch (error) {
        console.error("Failed to load summary: ", error);
      }
    };

    fetchSummary();
  }, []);

  const handleSearch = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const response = await fetch(
        `/papi/open-dashboard/user/search/${searchQuery}`
      );
      const result: UserSearchResponse = await response.json();
      if (
        result.response.userProfiles &&
        result.response.userProfiles.length > 0
      ) {
        setUserList(result.response.userProfiles);
        setSearchError("");
      } else {
        setUserList([]);
        setSearchError("Oops! We cannot find that user profile");
      }
    } catch (error) {
      if ((error as any).message === "no users matched the requested query") {
        setSearchError("Oops! We cannot find that user profile");
      } else {
        setSearchError("Oops! We cannot find that user profile");
        console.error("Search failed: ", error);
      }
      setUserList([]);
    }
  };

  const statistics = [
    {
      label: "Questions Uploaded",
      value: summary.QuestionCount,
      icon: "fa fa-question text-success",
    },
    {
      label: "Institutions Registered",
      value: summary.InstitutionCount,
      icon: "fa fa-university text-default",
    },
    {
      label: "Instructors Available",
      value: summary.InstructorCount,
      icon: "fa fa-user text-danger",
    },
    {
      label: "Students Enrolled",
      value: summary.StudentCount,
      icon: "fa fa-graduation-cap text-info",
    },
    {
      label: "Practices held by students",
      value: summary.PracticeCount,
      icon: "fa fa-clipboard text-warning",
    },
  ];

  return (
    <div className="p-4 bg-[#f1f4f5] h-full w-full overflow-y-auto">
      <div className="text-left my-4">
        <h3 className="text-xl font-semibold text-[#797979]">
          Explore More...
        </h3>
      </div>
      <SearchBar
        onSearch={handleSearch}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        placeholder={"Search user profiles"}
      />
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-4">
        {userList.length > 0 ? (
          userList.map((profile) => (
            <UserProfileCard
              key={profile.id}
              name={`${profile.firstName} ${profile.lastName}`}
              location={`${profile.city}, ${profile.state}, ${profile.country}`}
              description={profile.aboutMe}
              skype={profile.skype}
              email={profile.email}
            />
          ))
        ) : (
          <div className="text-red-500 font-bold col-span-full">
            {searchError}
          </div>
        )}
      </div>
      <Statistics stats={statistics} />
    </div>
  );
};

export default ExploreMorePage;
