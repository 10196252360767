import React from "react";
import { IonButton } from "@ionic/react";
import {
  PracticeSession,
  Practice,
  PapiError,
} from "../../models/PracticeSession";
import { InitializeNextPracticeOfPracticeSessionByID } from "../../papiclient/client";
import "./PracticeSession.css";

interface NextButtonProps {
  pSession: PracticeSession;
  requestStatus: NextPracticeRequestStatus;
  setRequestStatus: (req: NextPracticeRequestStatus) => void;
  setPracticeSession: (ps: PracticeSession) => void;
}

export interface NextPracticeRequestStatus {
  submitting: boolean;
  error: PapiError | null;
}
const NextButton: React.FC<NextButtonProps> = ({
  pSession,
  requestStatus,
  setRequestStatus,
  setPracticeSession,
}) => {
  const handleNextButtonClick = () => {
    setRequestStatus({ submitting: true, error: null });

    InitializeNextPracticeOfPracticeSessionByID(pSession.id)
      .then(async (newPractice: Practice) => {
        var clone = pSession.clone();
        clone.appendPractice(newPractice);
        clone.changeToNextPractice();
        setPracticeSession(clone);

        setRequestStatus({
          submitting: false,
          error: null,
        });
      })
      .catch(async (err: PapiError) => {
        setRequestStatus({
          submitting: false,
          error: err,
        });
      });
  };

  return (
    <IonButton
      className="button-text-normal"
      fill="solid"
      color="primary"
      slot="end"
      id="nextBtn"
      onClick={handleNextButtonClick}
      disabled={requestStatus.submitting}
    >
      {requestStatus.submitting ? "Loading ..." : "Next >>"}
    </IonButton>
  );
};

export default NextButton;
