import { IonImg } from "@ionic/react";
import React, { useState } from "react";
import { useUser } from "../../context/UserContext";

interface UserProfileCardProps {
  name: string;
  location: string;
  description: string;
  skype: string;
  email: string;
}

const UserProfileCard: React.FC<UserProfileCardProps> = ({
  name,
  location,
  description,
  skype,
  email,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { user } = useUser();

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className="border border-[#F1F4F5] flex-col flex pb-4 bg-white text-[#797979] rounded shadow-sm w-full max-w-sm">
      <div className="font-bold mb-2 bg-[#fafafa] py-2 w-full px-4">{name}</div>
      <div className="flex-grow px-4 flex">
        <div className="flex-grow">
          <div
            className={`text-[#797979] mb-4 text-[14px] font-bold ${
              isExpanded ? "" : "max-h-[6.6rem] overflow-hidden"
            }`}
            style={{
              maxWidth: "250px",
              position: "relative",
            }}
          >
            {description}
            {!isExpanded && description?.length > 100 && (
              <span
                className="text-blue-500 underline cursor-pointer ml-2 absolute bottom-0 right-0 bg-white"
                onClick={toggleExpand}
              >
                ... See More
              </span>
            )}
          </div>
          {isExpanded && (
            <button
              onClick={toggleExpand}
              className="text-blue-500 underline text-nowrap mb-4"
            >
              See Less
            </button>
          )}
          <div className="text-gray-500">{location}</div>
          {user ? (
            <div className="text-[14px]">
              <div className="text-gray-500 mt-2">Skype: {skype}</div>
              <div className="text-blue-500 mt-2">
                Email:{" "}
                <a href={`mailto:${email}`} className="text-blue-500 underline">
                  {email}
                </a>
              </div>
            </div>
          ) : (
            <div className="text-red-500 mt-2">
              Please sign up or login in order to see the contact details.
            </div>
          )}
        </div>
        <IonImg
          src={process.env.PUBLIC_URL + "/assets/img/account-avatar.png"}
          alt="Account Icon"
          className="w-16 h-16 rounded-full ml-4"
        ></IonImg>
      </div>
    </div>
  );
};

export default UserProfileCard;
