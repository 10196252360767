import React, { useState, useEffect } from "react";
import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
  IonIcon,
  IonBadge,
  IonSpinner,
} from "@ionic/react";
import swal from "sweetalert";
import { connect } from "../data/connect";
import { RouteComponentProps } from "react-router";
import { useHistory } from "react-router";
import {
  newspaperOutline,
  copy,
  ban,
  remove,
  download,
  pencil,
  bagRemoveOutline,
} from "ionicons/icons";
import Pagination from "../components/CustomPagination/Pagination";
import "./PracticeSessionPage.scss";
import { fetchPracticeTemplateData } from "../papiclient/client";

interface OwnProps extends RouteComponentProps {}

interface StateProps {
  darkMode: boolean;
}
export interface PracticeTemplateData {
  id: number;
  name: string;
  topicName: string;
  examNames: string[];
  totalQuestions: number;
  totalSections: number;
  status: string;
  liveExamEndsAt: Date | null;
  liveExamStartsAt: Date | null;
}

interface StatusBadgeProps {
  ptInfo: PracticeTemplateData;
}

interface ActionsProps {
  ptInfo: PracticeTemplateData;
  setPracticeTemplateData: React.Dispatch<React.SetStateAction<PracticeTemplateData[]>>
}

const obsoletePracticeTemplate = async (ptId:number,setPracticeTemplateData:React.Dispatch<React.SetStateAction<PracticeTemplateData[]>>) => {
  try {
    const response = await fetch(`/papi/institution/practice-template/${ptId}/obsolete`, {
      method: 'PUT',
    });
    const data = await response.json();

    if (data && data.success) {
      fetchPracticeTemplateData(setPracticeTemplateData)
      swal({
        title: "Done!",
        text: "Your practice template has been obsoleted.",
        icon: "success",
      });
    } else {
      throw new Error(data.message || 'Failed to obsolete the template');
    }
  } catch (error: unknown) {
    let errorMessage = 'An unknown error occurred';

    if (error instanceof Error) {
      errorMessage = error.message;
    } else if (typeof error === 'string') {
      errorMessage = error;
    } else if (error && typeof error === 'object') {
      errorMessage = JSON.stringify(error);
    }

    swal({
      title: "Error!",
      text: `Failed to obsolete the practice template: ${errorMessage}`,
      icon: "error",
    });
  }
  }

  const obsoleteTemplate = (ptId:number,setPracticeTemplateData:  React.Dispatch<React.SetStateAction<PracticeTemplateData[]>>) =>{
  swal({
    title: "Are you sure?",
    text: "You are about to an obsolete a practice template.",
    icon: "warning",
    buttons: {
      cancel: {
        text: "Cancel",
        value: null,
        visible: true,
        className: "cancel-btn",
        closeModal: true,
      },
      confirm: {
        text: "Yes, Continue!",
        value: true,
        visible: true,
        className: "confirm-btn",
        closeModal: true,
      },
    },
  }).then((value) => {
    if (value) {
      obsoletePracticeTemplate(ptId, setPracticeTemplateData);
    }
  });
};

const releasePracticeTemplate = async (ptId:number,setPracticeTemplateData:  React.Dispatch<React.SetStateAction<PracticeTemplateData[]>>) => {
  try {
    const response = await fetch(`/papi/institution/practice-template/${ptId}/release`, {
      method: 'POST',
    });
    const data = await response.json();

    if (data && data.success) {
      fetchPracticeTemplateData(setPracticeTemplateData)
      swal({
        title: "Done!",
        text: "Your practice template has been released.",
        icon: "success",
      });
    } else {
      throw new Error(data.message || 'Failed to release the template');
    }
  } catch (error: unknown) {
    let errorMessage = 'An unknown error occurred';

    if (error instanceof Error) {
      errorMessage = error.message;
    } else if (typeof error === 'string') {
      errorMessage = error;
    } else if (error && typeof error === 'object') {
      errorMessage = JSON.stringify(error);
    }

    swal({
      title: "Error!",
      text: errorMessage,
      icon: "error",
    });
  }
  }

const releaseTemplate = (ptId:number,setPracticeTemplateData:React.Dispatch<React.SetStateAction<PracticeTemplateData[]>>) =>{
  swal({
    title: "Are you sure?",
    text: "This template will get released to the students and you will not be able to make any further changes to it.",
    icon: "warning",
    buttons: {
      cancel: {
        text: "Cancel",
        value: null,
        visible: true,
        className: "cancel-btn",
        closeModal: true,
      },
      confirm: {
        text: "Yes, Continue!",
        value: true,
        visible: true,
        className: "confirm-btn",
        closeModal: true,
      },
    },
  }).then((value) => {
    if (value) {
      releasePracticeTemplate(ptId,setPracticeTemplateData);
    }
  });
};

const deletePracticeTemplate = async (ptId: number, setPracticeTemplateData: React.Dispatch<React.SetStateAction<PracticeTemplateData[]>>) => {
  try {
    const response = await fetch(`/papi/institution/practice-template/${ptId}/delete`, {
      method: 'DELETE',
    });
    const data = await response.json();

    if (data && data.success) {
      fetchPracticeTemplateData(setPracticeTemplateData)
      swal({
        title: "Done!",
        text: "Your practice template has been removed.",
        icon: "success",
      });
    } else {
      throw new Error(data.message || 'Failed to delete the practice');
    }
  } catch (error: unknown) {
    let errorMessage = 'An unknown error occurred';

    if (error instanceof Error) {
      errorMessage = error.message;
    } else if (typeof error === 'string') {
      errorMessage = error;
    } else if (error && typeof error === 'object') {
      errorMessage = JSON.stringify(error);
    }

    swal({
      title: "Error!",
      text: errorMessage,
      icon: "error",
    });
  }
}

const confirmAndDeletePracticeTemplate = (ptId: number, setPracticeTemplateData: React.Dispatch<React.SetStateAction<PracticeTemplateData[]>>) => {
  swal({
    title: "Are you sure?",
    text: "You are about to delete a practice template.",
    icon: "warning",
    buttons: {
      cancel: {
        text: "Cancel",
        value: null,
        visible: true,
        className: "cancel-btn",
        closeModal: true,
      },
      confirm: {
        text: "Yes, Continue!",
        value: true,
        visible: true,
        className: "confirm-btn",
        closeModal: true,
      },
    },
  }).then((value) => {
    if (value) {
      deletePracticeTemplate(ptId, setPracticeTemplateData);
    }
  });
};

export const StatusBadge: React.FC<StatusBadgeProps> = ({ ptInfo }) => {
  const now = new Date();

  const isLiveExam = (ptInfo: PracticeTemplateData) => {
    return ptInfo.liveExamStartsAt !== null;
  };

  const liveExamStartsAfter = (date: Date | null) => {
    if (date === null) return false;
    return new Date(date) > now;
  };

  if (!isLiveExam(ptInfo)) {
    switch (ptInfo.status) {
      case 'released':
        return (
          <IonBadge color="success" title="All students can use this template multiple times.">
            Mock Exam Released
          </IonBadge>
        );
      case 'obsolete':
        return (
          <IonBadge color="medium" title="This mock exam has been made obsolete by your instructor and is not available for new practice anymore.">
            Obsoleted
          </IonBadge>
        );
      default:
        return (
          <IonBadge color="primary" title="All students can use this template multiple times.">
            Mock Exam Not Released
          </IonBadge>
        );
    }
  } else {
    switch (ptInfo.status) {
      case 'released':
        return (
          <IonBadge color="danger" title={`Exam starts at ${ptInfo.liveExamStartsAt}`}>
            Exam Scheduled
          </IonBadge>
        );
      case 'obsolete':
        return (
          <IonBadge color="medium" title="This mock exam has been made obsolete by your instructor and is not available for new practice anymore.">
            Obsoleted
          </IonBadge>
        );
      default:
        if (liveExamStartsAfter(ptInfo.liveExamStartsAt)) {
          return (
            <IonBadge color="primary" title={`Exam is setup to start at ${ptInfo.liveExamStartsAt}`}>
              Exam Not Released
            </IonBadge>
          );
        } else {
          return (
            <IonBadge color="warning" title={`Exam was supposed to be conducted at ${ptInfo.liveExamStartsAt}`}>
              Exam Expired
            </IonBadge>
          );
        }
    }
  }
};

export const ActionBar = ({ ptInfo,setPracticeTemplateData }: ActionsProps) => {
  const history = useHistory();

  const now = new Date();

  const isLiveExam = (ptInfo: PracticeTemplateData) => {
    return ptInfo.liveExamStartsAt !== null;
  };

  const liveExamEndsAt = (liveExamEndTime: Date | null) => {
    if (liveExamEndTime === null) return false;
    const endTime = new Date(liveExamEndTime).getTime();
    const currentTime = now.getTime();
    const result = endTime <= currentTime;
    return result;
  };

  return (
    <div
      slot="end"
      className="d-flex justify-content-center"
      style={{ gap: "10px" }}
    >
      {ptInfo.status === "draft" && (
        <>
          <i
            className="fa fa-pencil font-size-icon clickable-icon"
            id={`edit_${ptInfo.id}`}
            style={{ color: "#29b6f6" }}
            title="Edit practice"
            onClick={() => {
              window.location.assign(`/PracticeTemplates/${ptInfo.id}/Update`);
            }}
          />

          <i
            className="fa-regular fa-trash-can font-size-icon clickable-icon"
            title="Delete this practice."
            style={{ color: "#ef5350" }}
            id={`delete_${ptInfo.id}`}
            onClick={() => {
              confirmAndDeletePracticeTemplate(ptInfo.id, setPracticeTemplateData)
            }}
          />

          <i
            className="fa fa-check-circle font-size-icon clickable-icon"
            title="Release."
            color="success"
            style={{ color: "#34c73b" }}
            id={`release_${ptInfo.id}`}
            onClick={() => {
              releaseTemplate(ptInfo.id,setPracticeTemplateData)
            }}
          />
        </>
      )}

      <i
        className="fa fa-copy font-size-icon clickable-icon"
        title="Create new practice from this."
        style={{ color: "#34c73b" }}
        id={`dup_template_${ptInfo.id}`}
        onClick={() => {
          window.location.assign(
            `/PracticeTemplates/${ptInfo.id}/CopyTemplate`
          );
        }}
      />

      <i
        className="fa fa-download font-size-icon clickable-icon"
        title="Download this practice."
        style={{ color: "#29b6f6" }}
        id={`download_${ptInfo.id}`}
        onClick={() => {
          window.location.assign(`/PracticeTemplates/${ptInfo.id}/pdf`);
        }}
      />

      {ptInfo.status !== "draft" && (
        <>
          <i
            className="fa fa-eye font-size-icon clickable-icon"
            title="View this template."
            style={{ color: "#333b4d" }}
            id={`view_${ptInfo.id}`}
            onClick={() => {
              window.location.assign(`/PracticeTemplateViewForm/${ptInfo.id}`);
            }}
          />

          {ptInfo.status === 'released' && (
            <>
              <i
                className="fa fa-times font-size-icon clickable-icon"
                id={`obsolete_${ptInfo.id}`}
                style={{ color: "#ef5350" }}
                title="Obsolete"
                data-confirm="You are about to obsolete a practice template."
                data-action={`/PracticeTemplates/Obsolete`}
                onClick={() => {
                  obsoleteTemplate(ptInfo.id, setPracticeTemplateData)
                }}
              />
              {isLiveExam(ptInfo) && liveExamEndsAt(ptInfo.liveExamEndsAt) &&(
                <>
                  <i
                    className="fa fa-dashboard font-size-icon clickable-icon"
                    style={{ color: "#333b4d" }}
                    title="Show exam results"
                    onClick={() => {
                      window.location.assign(`/PracticeTemplates/${ptInfo.id}/summary`);
                    }}
                  />
                </>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

const Pt: React.FC<OwnProps & StateProps> = ({ darkMode }) => {
  const [practiceTemplateData, setPracticeTemplateData] = useState<
    PracticeTemplateData[]
  >([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [displayedData, setDisplayedData] = useState<PracticeTemplateData[]>(
    []
  );
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchPracticeTemplateData((data) => {
      setPracticeTemplateData(data);
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    const initialData =
      practiceTemplateData.length > 0
        ? practiceTemplateData.slice(0, rowsPerPage)
        : [];
    setDisplayedData(initialData);
  }, [practiceTemplateData, rowsPerPage]);

  const updateDisplayedData = (newPage: number, newRowsPerPage: number) => {
    const startIndex = (newPage - 1) * newRowsPerPage;
    const endIndex = startIndex + newRowsPerPage;
    const newData = practiceTemplateData.slice(startIndex, endIndex);
    setDisplayedData(newData);
    setCurrentPage(newPage);
  };

  const onPageChange = (newPage: number) => {
    updateDisplayedData(newPage, rowsPerPage);
  };

  const handleRowsPerPageChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    updateDisplayedData(1, newRowsPerPage);
  };

  const toCamelCase = (str: String) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const renderIonCols = (ptInfo: PracticeTemplateData, index: number) => {
    const ionCols = [
      { size: "8", sizeMd: "2", content: ptInfo.name },
      {
        size: "12",
        sizeMd: "2",
        content: ptInfo.examNames.join(", "),
        className: "ion-hide-md-down",
      },
      {
        size: "12",
        sizeMd: "1",
        content: ptInfo.topicName,
        className: "ion-hide-md-down",
      },
      {
        size: "1",
        sizeMd: "1",
        content: ptInfo.totalSections,
        className: "ion-hide-md-down",
      },
      {
        size: "1",
        sizeMd: "1",
        content: ptInfo.totalQuestions,
        className: "ion-hide-md-down",
      },
      {
        size: "12",
        sizeMd: "2",
        content: <StatusBadge ptInfo={ptInfo} />,
        className: "ion-hide-md-down",
      },
      {
        size: "4",
        sizeMd: "1",
        content: <ActionBar ptInfo={ptInfo} setPracticeTemplateData={setPracticeTemplateData} />,
        className: "ion-text-center custom-action-bar",
      },
    ];

    return ionCols.map((col, colIndex) => {
      const isEvenRow = index % 2 !== 0;
      const isCustomWidthColumn = [3, 4].includes(colIndex);
      return (
        <IonCol
          key={index}
          size={isCustomWidthColumn ? "auto" : col.size}
          size-md={isCustomWidthColumn ? "auto" : col.sizeMd}
          className={`${col.className} header-cell bordered-cell custom-col ${
            isEvenRow ? "even-row-background" : "odd-row-background"
          }`}
          style={{
            fontFamily: "Roboto, sans-serif",
            ...(darkMode ? { background: "#1f1f1f", color: "white" } : {}),
          }}
        >
          <div style={{ width: isCustomWidthColumn ? "80px" : "" }}>
            {col.content}
          </div>
        </IonCol>
      );
    });
  };

  const headerColumns = [
    { size: "8", sizeMd: "2", label: "Name", className: "header-cell" },
    {
      size: "12",
      sizeMd: "2",
      label: "Exams",
      className: "header-cell ion-hide-md-down",
    },
    {
      size: "12",
      sizeMd: "1",
      label: "Topic",
      className: "header-cell ion-hide-md-down",
    },
    {
      size: "12",
      sizeMd: "1",
      label: "Total Sections",
      className: "ion-hide-md-down header-cell",
    },
    {
      size: "12",
      sizeMd: "1",
      label: "Total Questions",
      className: "ion-hide-md-down header-cell",
    },
    {
      size: "12",
      sizeMd: "2",
      label: "Status",
      className: "ion-hide-md-down header-cell",
    },
    {
      size: "4",
      sizeMd: "1",
      label: "Actions",
      className: "ion-text-center header-cell",
    },
  ];

  return (
    <IonPage id="practice-template-page">
      <IonHeader translucent={true}>
        <IonToolbar>
          <IonTitle>{loading ? "Loading ..." : "Practice Templates"}</IonTitle>
          {!loading && (practiceTemplateData || []).length !== 0 && (
            <IonButton
              className="button-text-normal"
              color="primary"
              size="small"
              slot="end"
              fill="solid"
              onClick={() => {
                window.location.assign(`/PracticeTemplates/Create`);
              }}
            >
              Create
            </IonButton>
          )}
        </IonToolbar>
      </IonHeader>

      {!loading && practiceTemplateData.length ? (
        <IonContent>
          <IonGrid className="custom-grid large-screen">
            <IonRow className="row-header">
              {headerColumns.map((column, index) => {
                const isCustomWidthColumn = [3, 4].includes(index);

                return (
                  <IonCol
                    key={index}
                    size={isCustomWidthColumn ? "auto" : column.size}
                    size-md={isCustomWidthColumn ? "auto" : column.sizeMd}
                    className={`${column.className} header-cell custom-header-cell`}
                    style={{
                      fontFamily: "Roboto, sans-serif",
                      ...(darkMode
                        ? { background: "#1f1f1f", color: "white" }
                        : {}),
                    }}
                  >
                    <div style={{ width: isCustomWidthColumn ? "80px" : "" }}>
                      {column.label}
                    </div>
                  </IonCol>
                );
              })}
            </IonRow>

            {displayedData.map((ptItem, index) => (
              <IonRow key={index} className="custom-data-row">
                {renderIonCols(ptItem, index)}
              </IonRow>
            ))}
          </IonGrid>
          <Pagination
            currentPage={currentPage}
            totalPages={Math.ceil(practiceTemplateData.length / rowsPerPage)}
            onPageChange={onPageChange}
            rowsPerPage={rowsPerPage}
            darkMode={darkMode}
            handleRowsPerPageChange={handleRowsPerPageChange}
            rowsPerPageOptions={[5, 10, 15, 20, 25]}
          />
        </IonContent>
      ) : (
        !loading && (
          <IonContent>
            <div className={darkMode ? "custom-alert-dark" : "custom-alert"}>
              <div>
                <h4 className={darkMode ? "alert-text-white" : ""}>
                  Looks like you have not yet created any practice templates!
                </h4>
                <p className={darkMode ? "alert-text-white" : ""}>
                  Do you want to create now?
                </p>
                <IonButton
                  className="button-text-normal"
                  color="primary"
                  size="small"
                  slot="end"
                  fill="solid"
                  onClick={() => {
                    window.location.assign(`/PracticeTemplates/Create`);
                  }}
                >
                  Create
                </IonButton>
              </div>
            </div>
          </IonContent>
        )
      )}
    </IonPage>
  );
};

export default connect<OwnProps, StateProps>({
  mapStateToProps: (state) => ({
    darkMode: state.user.darkMode,
  }),
  component: Pt,
});
