import { StatusCodes } from "http-status-codes";

interface ApiResponse<T> {
  data?: T;
  error?: { code: number; message: string };
}

export const handleUnauthorized = () => {
  window.location.href = '/change_role';
};

export async function fetchWrapper(url: string, options?: RequestInit): Promise<Response> {
    const response = await fetch(url, options);
    if (!response.ok) {
      if (response.status === StatusCodes.UNAUTHORIZED) {
        handleUnauthorized();
      }
    }
    return response;
  }