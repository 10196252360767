import React, { CSSProperties, ReactElement } from "react";
import PropTypes from "prop-types";
import "./tooltip.css";

interface ReactToolTipProps {
  title: string;
  children?: ReactElement;
  position?: "top" | "bottom" | "left" | "right";
  containerClass?: string;
  theme?: "light" | "dark";
  isDom?: boolean;
  style?: CSSProperties;
}

const ReactToolTip: React.FC<ReactToolTipProps> = ({
  title = "sample",
  children,
  position = "bottom",
  containerClass = "",
  theme = "light",
  isDom,
  style,
}) => {
  const tooltipTextStyle = {
    fontSize: "14px",
    ...style,
  };
  return (
    <div className={`tooltip ${containerClass}`}>
      {children}
      <div
        className={`tooltiptext ${
          theme === "dark" ? "dark" : "light"
        } tooltip-${position}`}
        style={tooltipTextStyle}
      >
        {isDom ? (
          <div dangerouslySetInnerHTML={{ __html: title }}></div>
        ) : (
          title
        )}
        <span className="arrow"></span>
      </div>
    </div>
  );
};

ReactToolTip.defaultProps = {
  title: "sample",
  children: <div />,
  position: "bottom",
  containerClass: "",
  theme: "light",
};

ReactToolTip.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.element,
  position: PropTypes.oneOf(["top", "bottom", "left", "right"]),
  containerClass: PropTypes.string,
  theme: PropTypes.oneOf(["light", "dark"]),
  isDom: PropTypes.bool,
};

export default ReactToolTip;
