import React, { useState, useEffect } from "react";
import { IonMenuToggle, IonItem, IonLabel } from "@ionic/react";
import { useHistory, useLocation } from "react-router-dom";
import ReactToolTip from "./Tooltip/Tooltip";
import { Pages } from "./Menu";

interface Props {
  list: Pages[];
  data: any | null;
}

const RenderMenuListItems: React.FC<Props> = ({ list, data }) => {
  const [position, setPosition] = useState<"top" | "bottom">("top");
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    // Check if "Super Admin Dashboard" item is present and user is super admin
    const isSuperAdminDashboardVisible = list.some(
      (route) => route.title === "Super Admin Dashboard"
    );
    const isUserSuperAdmin = data?.isSuperAdmin;

    // Set tooltip position based on conditions
    if (isSuperAdminDashboardVisible && isUserSuperAdmin) {
      setPosition("bottom");
    } else {
      setPosition("top");
    }
  }, [list, data?.isSuperAdmin]);

  return (
    <>
      {list
        .filter((route) => {
          if (route.title === "Super Admin Dashboard") {
            return data?.isSuperAdmin;
          }
          return !!route.path;
        })
        .map((p, index) => {
          const path =
            p.title === "Practice Templates" && data?.role === "instructor"
              ? "ui/PracticeTemplates/List"
              : "/PracticeTemplates/Student/List";
          const shouldRenderToolTip = index > 0;

          return (
            <>
              {shouldRenderToolTip ? (
                <ReactToolTip
                  title={p.title}
                  position={position}
                  theme="dark"
                  key={p.title}
                  style={{ zIndex: list.length - index }}
                >
                  <IonMenuToggle auto-hide={false}>
                    <IonItem
                      detail={false}
                      routerLink={p.path}
                      routerDirection="none"
                      className={
                        location.pathname.startsWith(p.path)
                          ? "selected"
                          : undefined
                      }
                      onClick={(e) => {
                        if (p.isLaravelPage === undefined) {
                          e.preventDefault();
                          if (p.path !== location.pathname) {
                            history.push(p.path);
                          }
                        } else {
                          window.location.assign(p.path);
                        }
                      }}
                    >
                      <i
                        style={{ color: "#838F9A" }}
                        className={`label-icon ${p.icon}`}
                      />
                      <IonLabel
                        style={{ color: "#838F9A" }}
                        data-tip
                        data-for={`tooltip-${p.title}`}
                      >
                        {p.title}
                      </IonLabel>
                    </IonItem>
                  </IonMenuToggle>
                </ReactToolTip>
              ) : (
                <IonMenuToggle auto-hide={false}>
                  <IonItem
                    detail={false}
                    routerLink={p.path}
                    routerDirection="none"
                    className={
                      location.pathname.startsWith(p.path)
                        ? "selected"
                        : undefined
                    }
                    onClick={(e) => {
                      if (p.isLaravelPage === undefined) {
                        e.preventDefault();
                        if (p.path !== location.pathname) {
                          history.push(p.path);
                        }
                      } else {
                        window.location.assign(p.path);
                      }
                    }}
                  >
                    <i
                      style={{ color: "#838F9A" }}
                      className={`label-icon ${p.icon}`}
                    />
                    <IonLabel
                      style={{ color: "#838F9A" }}
                      data-tip
                      data-for={`tooltip-${p.title}`}
                      title={p.title}
                    >
                      {p.title}
                    </IonLabel>
                  </IonItem>
                </IonMenuToggle>
              )}
            </>
          );
        })}
    </>
  );
};

export default RenderMenuListItems;
