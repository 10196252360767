import { useState, useEffect, RefObject } from "react";
 
/**
 * Hook for handling closing when clicking outside of an element
 * @param {RefObject<HTMLElement>} el - Ref to the element you want to detect clicks outside of
 * @param {boolean} initialState - Initial state of the element's visibility
 * @returns {Array<boolean, React.Dispatch<React.SetStateAction<boolean>>>} - Array containing the current state and a function to set the state
 */

export const useDetectOutsideClick = (
  el: RefObject<HTMLElement>,
  initialState: boolean
): [boolean, React.Dispatch<React.SetStateAction<boolean>>] => {
  const [isActive, setIsActive] = useState(initialState);
 
  useEffect(() => {
    const onClick = (e: MouseEvent) => {
      // If the active element exists and is clicked outside of
      if (el.current !== null && !el.current.contains(e.target as Node)) {
        setIsActive(!isActive);
      }
    };
 
    // If the item is active (i.e., open) then listen for clicks outside
    if (isActive) {
      window.addEventListener("click", onClick);
    }
 
    return () => {
      window.removeEventListener("click", onClick);
    };
  }, [isActive, el]);
 
  return [isActive, setIsActive];
};