export const successWords = [
    "Great!",
    "Super!",
    "Excellent!",
    "That’s great.",
    "Fantastic!",
    "Good work!",
    "That’s right!",
    "Congratulations!",
    "That’s really nice.",
    "That’s clever",
  ];
  
  export const dangerWords = [
    "Never mind, better luck next time.",
    "It's alright, try harder next time.",
    "Don't worry, you will do great.",
    "Doesn't matter, we hope things will turn out fine.",
    "It's fine, you just need to push a bit.",
    "It's ok, we're rooting for you.",
    "That's alright, you are going to be amazing.",
  ];
  