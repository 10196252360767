"use strict";

import React from "react";
import { PapiError, PapiFilePOSTResponse } from "../../models/PracticeSession";
import { UploadUserFile } from "../../papiclient/client";
import { Recorder } from "../AudioRecorder/Recorder";
import swal from "sweetalert";

interface practiceSessionAudioRecorderProps {
  onChange: (id?: number) => void; // on-cahcnge callback to recieve the file-id
  url?: string;
  disabled?: boolean;
  hideHeader?: boolean;
}

export const PracticeSessionAudioRecorder: React.FC<practiceSessionAudioRecorderProps> = ({
  onChange,
  url,
  disabled = false,
  hideHeader = false,
}) => {
  const saveRecording = async (b: Blob): Promise<PapiFilePOSTResponse> => {
    return UploadUserFile(new File([b], "audio-recording.wav"));
  };

  const handleRecordingStopped = (audioBlob: Blob) => {
    swal({
      title: "Uploading recording...",
      text: "Please wait.",
      icon: "info",
      buttons: [false],
      closeOnClickOutside: false,
      closeOnEsc: false,
    });

    saveRecording(audioBlob)
      .then((resp: PapiFilePOSTResponse) => {
        onChange(resp.id);

        setTimeout(function () {
          if (swal.close !== undefined) {
            swal.close();
          }
        }, 1000);
      })
      .catch((err: PapiError) => {
        console.log(err);
        if (swal.close !== undefined) {
          swal.close();
        }
        swal({
          title: "Failed to upload recording",
          text: "Please check your internet upload connection.",
          icon: "error",
          buttons: [false, "Retry ..."],
        });
      });
  };

  return (
    <div className="form-group col-lg-12">
      <Recorder
        title="Record your audio response here."
        url={url}
        hideHeader={hideHeader}
        handleRecordingStopped={handleRecordingStopped}
        handleReset={onChange}
        disabled={disabled}
      />
      {/* <span className="error invalid-feedback" role="alert"> */}
      {/*   <strong>{this.props.errors[this.props.audioIpfsHashName][0]}</strong> */}
      {/* </span> */}
    </div>
  );
};
