import React, { useEffect, useState } from "react";
import { IonHeader, IonText, IonRouterLink, IonImg } from "@ionic/react";
import { book } from "ionicons/icons";
import DropdownMenu from "./DropdownMenu";
import "./navbar.css";
import { useUser } from "../../context/UserContext";
interface NavbarProps {
  title: string;
  darkMode: boolean;
}

const Navbar: React.FC<NavbarProps> = ({ title, darkMode }) => {
  const { user, loading } = useUser();

  if (loading) {
    return (
      <div className="loader-container">
        <div className="loader">Loading...</div>
      </div>
    );
  }

  return (
    <IonHeader
      className={`navbar-header ${
        darkMode ? "dark-mode-color" : "light-mode-color"
      }`}
    >
      <div className="navbar-content">
        <IonRouterLink routerLink="/" style={{ color: "black" }}>
          <div className="navbar-left">
            <IonImg
              src={process.env.PUBLIC_URL + "/assets/img/wordline-inverted.png"}
              alt="App Icon"
              class="logo-img md"
            ></IonImg>
          </div>
        </IonRouterLink>

        <div className="navbar-right">
          {user?.institutionName && (
            <div className="info-container">
              <IonText
                style={{ textTransform: "capitalize" }}
                className="nav-label navbar-font-color"
              >
                Logged in as <b>{user?.role}</b> in
                <IonText className="insttNameBanner">
                  <a
                    href={`/institution/profile/${user?.institutionId}`}
                    className="navbar-link navbar-font-color"
                    id="loginStatus"
                  >
                    {user?.institutionName}
                  </a>
                </IonText>
              </IonText>
            </div>
          )}
          <div className="navbar-dropdown">
            <div className="navbar-dropdown">
              <DropdownMenu userData={user} darkMode={darkMode} />
            </div>
          </div>
        </div>
      </div>
    </IonHeader>
  );
};

export default Navbar;
