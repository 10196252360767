import React, { useState } from "react";

import {
  IonToolbar,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonBadge,
  IonItem,
  IonText,
  IonLabel,
  IonIcon,
  IonButton,
  IonPopover,
  IonContent,
} from "@ionic/react";

import {
  DifficultyLevelToColor,
  DifficultyLevelToString,
  LearningMode,
  PracticeSession,
  PapiError,
  QuestionPatternTypes,
} from "../../models/PracticeSession";

import AnswerOptions from "./AnswerOptions";
import NextButton from "./NextButton";
import SkipButton from "./SkipButton";
import SubmitButton from "./SubmitButton";
import SubmitAnswerSheet from "./SubmitAnswerSheet";
import { StatusCodes } from "http-status-codes";

import "./PracticeSession.css";
import swal from "sweetalert";
import ReactQuill from "react-quill";
import {
  constructOutline,
  informationCircle,
  mailOutline,
  thumbsUpOutline,
} from "ionicons/icons";
import InfoModal from "./InfoModal/InfoModal";
import { useSSLContext } from "../../context/SSLContext";
import { ADMIN_EMAIL } from "../../config/constants";

export interface NextPracticeRequestStatus {
  submitting: boolean;
  error: PapiError | null;
}

export interface AnswerSubmissionRequestStatus {
  submitting: boolean;
  error: PapiError | null;
}

export interface QuestionCardProps {
  pSession: PracticeSession;
  setPracticeSession: (ps: PracticeSession) => void;
  setShowReviewPage: (value: boolean) => void;
}

export const QuestionCard: React.FC<QuestionCardProps> = ({
  pSession,
  setPracticeSession,
  setShowReviewPage,
}) => {
  const [
    answerSubmissionReqStatus,
    setAnswerSubmissionReqStatus,
  ] = useState<AnswerSubmissionRequestStatus>({
    submitting: false,
    error: null,
  });

  const [
    nextPracticeReqStatus,
    setNextPracticeReqReqStatus,
  ] = useState<NextPracticeRequestStatus>({
    submitting: false,
    error: null,
  });

  const [textInput, setTextInput] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const handleInfoButtonClick = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const practice = pSession.getCurrentPractice();

  const endPractice = () => {
    if (pSession?.id) {
      fetch(`/papi/institution/practice-session/${pSession.id}/stop`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((resp: Response) => {
          if (resp.status !== StatusCodes.OK) {
            return Promise.reject({
              code: resp.status,
              message: `failed to end the practice-session: ${resp.status} / ${resp.statusText}`,
            } as PapiError);
          }

          window.location.assign(`/PracticeSession/Summary/${pSession.id}`);
        })
        .catch((err: PapiError) => {
          console.error(err);
        });
    }
  };

  const handleNotEnoughQuestions = () => {
    swal({
      title: "Not enough questions!",
      text:
        "This practice does not have enough questions which meet all the criteria. Please let your instructors know so that they can add more questions.",
      icon: "warning",
      buttons: {
        cancel: {
          text: "No, I would like to come back later...",
          value: null,
          visible: true,
          className: "cancel-btn",
          closeModal: true,
        },
        confirm: {
          text: "Yes, End Now!",
          value: true,
          visible: true,
          className: "confirm-btn",
          closeModal: true,
        },
      },
    }).then((result) => {
      setNextPracticeReqReqStatus({
        submitting: false,
        error: null,
      });
      setAnswerSubmissionReqStatus({
        submitting: false,
        error: null,
      });
      if (result) {
        endPractice();
      } else {
        console.error("Cancelled");
      }
    });
  };

  // when both the answer request and the next question request has been
  // submitted then reset both the buttons
  if (
    nextPracticeReqStatus.error !== null &&
    nextPracticeReqStatus.error.code === StatusCodes.NO_CONTENT
  ) {
    handleNotEnoughQuestions();
  }

  let scoreBadge = null;

  if (practice.practiceableType !== "App\\Models\\Question") {
    throw new Error("practice-type not supported: " + JSON.stringify(practice));
  }

  if (pSession.learningMode === LearningMode.PracticeTemplate) {
    if (pSession.practiceTemplate === undefined) {
      throw "practice-template is not defined for practice template based learning-mode";
    }

    var section = pSession.practiceTemplate.findSectionOfQuestionById(
      practice.question.id
    );

    if (section === null) {
      throw "unable to find the practice-template-section for the current question of the practice-template";
    }

    var sectionQuestionInfo = section.findSectionQuestionInfoById(
      practice.question.id
    );
    if (sectionQuestionInfo === null) {
      throw "unable to find the practice-template-section-question-info for the current question of the practice-template";
    }

    if (
      sectionQuestionInfo.score !== undefined &&
      sectionQuestionInfo.score > 0
    ) {
      scoreBadge = (

      <>
  <span slot="end">Marks:</span>
  <IonBadge className="ion-bagde-align" slot="end">
    {sectionQuestionInfo.score}
  </IonBadge>
  {section.negativeMarkingPercentage !== undefined &&
    section.negativeMarkingPercentage > 0 ? (
      <IonBadge color="danger" slot="end">
        Negative Marking:{" "}
        {(
          -(
            (sectionQuestionInfo.score * section.negativeMarkingPercentage) /
            100
          )
        ).toFixed(2)} Points
      </IonBadge>
    ) : null}
</>
);
    } else {
      scoreBadge = (
        <div slot ="end" className="div-allign">
          <span slot="end">Marks:</span>
          <IonBadge className="ion-bagde-align" slot="end">
            0
          </IonBadge>
        </div>
      );
    }

  }

  const answerSubmitted = practice.answerSubmitted;
  const handleTextInputChange = (value: string) => {
    setTextInput(value);
  };

  const modules = {
    toolbar: false,
  };

  const labelStyle = {
    fontSize: "16px",
    fontWeight: "bold",
    color: "#333",
  };

  const textStyle = {
    fontSize: "14px",
    color: "green",
  };

  const { sslError } = useSSLContext();

  if (sslError) {
    return (
      <IonContent className="ion-padding error-container ion-text-center">
        <IonText
          color="danger"
          className="ion-text error-message   ion-margin-top"
        >
          Recording Media Unavailable
          <br />
          {sslError}
        </IonText>
        <br />
        <IonText className="ion-text error-info  ion-margin-top ion-margin-bottom">
          Please try again after some time. If the issue persists, kindly
          contact our support team at{" "}
          <a href={`mailto:${ADMIN_EMAIL}`} className="admin-email">
            <IonText className="ion-text admin-email">{ADMIN_EMAIL}</IonText>
          </a>
          .
        </IonText>
      </IonContent>
    );
  }

  return (
    <IonCard>
      <IonCardHeader>
        <IonCardTitle id="questionSummary">
          <div
            id="practice-question-id"
            data-question-id={practice.question.id}
          ></div>
          {practice.question.groupedQuestionInfo === undefined
            ? practice.question.summary
            : practice.question.groupedQuestionInfo.groupParent.summary}
        </IonCardTitle>
        <IonCardSubtitle>
          <IonItem>
            {pSession.learningMode !== LearningMode.PracticeTemplate && (
              <>
                <IonIcon
                  slot="end"
                  id="info-icon-trigger"
                  icon={informationCircle}
                  color="primary"
                  ion-title="Learn More"
                  className="ion-margin-start ion-margin-top ion-activatable"
                  onClick={handleInfoButtonClick}
                  size="large"
                >
                </IonIcon>
                <InfoModal
                  isOpen={modalOpen}
                  onClose={handleCloseModal}
                  questionId={practice.question.id}
                />
              </>
            )}
            <IonBadge
              color={DifficultyLevelToColor(practice.question.difficultyLevel)}
              slot="end"
            >
              {DifficultyLevelToString(practice.question.difficultyLevel)}
            </IonBadge>
            {scoreBadge}
          </IonItem>
        </IonCardSubtitle>
      </IonCardHeader>
      <IonCardContent>
        {practice.question.groupedQuestionInfo && (
          <>
            <p>
              {practice.question.groupedQuestionInfo.groupParent.description.replace(/<p>/g, '').replace(/<\/p>/g, '')}
            </p>
            <br />
          </>
        )}
        {practice.question.groupedQuestionInfo && (
          <>
            <br />
            <strong>
              Sub Question #
              {practice.question.groupedQuestionInfo.subQuesSeq + 1}
            </strong>
            <br />
            <p>{practice.question.summary}</p>
            <br />
          </>
        )}
        <div
          dangerouslySetInnerHTML={{ __html: practice.question.description.replace(/<p>/g, '').replace(/<\/p>/g, '')}}
        />

        <br />
        <AnswerOptions
          key={pSession.getCurrentPracticeID()}
          pSession={pSession}
          setPracticeSession={setPracticeSession}
          textInput={textInput}
          onTextInputChange={handleTextInputChange}
        />
        {/* Answer Explanation Accordion */}
        {pSession.learningMode !== LearningMode.PracticeTemplate &&
          answerSubmitted && (
            <>
              {practice.question.pattern.typ === "One Word" && answerSubmitted && (
                <IonItem style={{ marginTop: "10px" }}>
                  {practice.question.options && (
                    <div>
                      {practice.question.options.map((item, index) => (
                        <div key={index} style={{ marginTop: "20px" }}>
                          <IonLabel style={labelStyle}>
                            Correct Answer:
                            <IonText style={textStyle}> {item.text}</IonText>
                          </IonLabel>
                        </div>
                      ))}
                    </div>
                  )}
                </IonItem>
              )}

              {practice.question.pattern.typ === QuestionPatternTypes.Essay && (
                <IonBadge
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "fit-content",
                  }}
                  color="secondary"
                  className="ion-margin-top ion-margin-bottom"
                >
                  <IonIcon
                    icon={thumbsUpOutline}
                    size="small"
                    className="ion-margin-end"
                  />
                  Your answer will be submitted for evaluation. You will get
                  notified once your instructors have evaluated it!
                </IonBadge>
              )}
              <IonItem>
                <IonLabel>Answer Explanation</IonLabel>
              </IonItem>

              <ReactQuill
                readOnly={true}
                value={practice.question.answerExplanation}
                theme="snow"
                modules={modules}
              />
            </>
          )}
      </IonCardContent>

      <IonToolbar>
        {(practice.answerSubmitted || practice.userSkipped) &&
        pSession.hasMorePractices() &&
        pSession.atLastKnownPractice() ? (
          <NextButton
            pSession={pSession}
            requestStatus={nextPracticeReqStatus}
            setRequestStatus={setNextPracticeReqReqStatus}
            setPracticeSession={setPracticeSession}
          />
        ) : null}

        {pSession.canSkipCurrentPractice() ? (
          <SkipButton
            pSession={pSession}
            setPracticeSession={setPracticeSession}
            setShowReviewPage={setShowReviewPage}
          />
        ) : null}

        {practice.practiceableType === "App\\Models\\Question" &&
            practice.question.pattern.isEssay() ? (
        <SubmitAnswerSheet pSession={pSession}
          requestStatus={answerSubmissionReqStatus}
          setRequestStatus={setAnswerSubmissionReqStatus}
          setPracticeSession={setPracticeSession}
          textInput={textInput}
          setShowReviewPage={setShowReviewPage}
        />) : null}

        <SubmitButton
          pSession={pSession}
          requestStatus={answerSubmissionReqStatus}
          setRequestStatus={setAnswerSubmissionReqStatus}
          setPracticeSession={setPracticeSession}
          textInput={textInput}
          setShowReviewPage={setShowReviewPage}
        />
      </IonToolbar>
      {answerSubmissionReqStatus.error !== null && (
        <IonText>{answerSubmissionReqStatus.error.message}</IonText>
      )}
      {nextPracticeReqStatus.error !== null && (
        <IonText>{nextPracticeReqStatus.error.message}</IonText>
      )}
    </IonCard>
  );
};
