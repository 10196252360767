import React, { useEffect, useState } from "react";

import { IonHeader, IonPage, IonTitle, IonToolbar } from "@ionic/react";
import "./PracticeSessionPage.scss";

import { PracticeSession } from "../models/PracticeSession";
import { PracticeSessionComponent } from "../components/PracticeSession/PracticeSession";
import { LoadPracticeSessionByID } from "../papiclient/client";
import { useHistory, useParams } from "react-router";
import swal from "sweetalert";
import { StatusCodes } from "http-status-codes";
import { setDarkMode } from "../data/user/user.actions";
import { connect } from "../data/connect";

interface StateProps {
  darkMode: boolean;
}

interface DispatchProps {
  setDarkMode: typeof setDarkMode;
}
interface PracticeSessionPageProps extends StateProps, DispatchProps { }

const PracticeSessionPage: React.FC<PracticeSessionPageProps> = ({ darkMode }) => {

  const { id } = useParams<{ id: string }>();
  const [pSession, setPracticeSession] = React.useState<
    PracticeSession | undefined
  >(undefined);

  const [loading, setLoading] = useState(true);
  const history = useHistory();

  useEffect(() => {
    if (id === undefined) {
      return;
    }

    const pSessionID = parseInt(id);

    LoadPracticeSessionByID(pSessionID)
      .then((ps: PracticeSession) => {
        if (!ps.hasStarted()) {
          return ps.start().then(() => {
            setPracticeSession(ps);
            setLoading(false);
          });
        }
        setPracticeSession(ps);
        setLoading(false);
      })
      .catch((reason) => {
        if (reason?.code === StatusCodes.NO_CONTENT) {
          swal({
            title: "Not enough questions!",
            text:
              "This practice does not have enough questions which meet all the criteria. Please let your instructors know so that they can add more questions.",
            icon: "warning",
            buttons: [false],
            closeOnClickOutside: true,
            closeOnEsc: false,
            className: darkMode ? 'sweat-alert-css-darkmode' : '',
            timer: 3000,
          });
          history.push(`/PracticeSession/List`);
        }
      });
  }, [id]);

  return (
    <IonPage id="practice-session-page">
      {loading ? (
        <IonHeader translucent={true}>
          <IonToolbar>
            <IonTitle>Loading ...</IonTitle>
          </IonToolbar>
        </IonHeader>
      ) : (
        <PracticeSessionComponent
          darkMode={darkMode}
          pSession={pSession}
          setPracticeSession={setPracticeSession}
        />
      )}
    </IonPage>
  );
};
export default connect<PracticeSessionPageProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    darkMode: state.user.darkMode,
  }),
  mapDispatchToProps: {
    setDarkMode,
  },
  component: PracticeSessionPage,
});
