// CoolFeaturesSection.tsx
import React from "react";
import { IonContent, IonGrid, IonRow, IonCol, IonText } from "@ionic/react";
import FeatureCard from "./FeatureCard";
import {
  pencilOutline,
  peopleOutline,
  pieChartOutline,
  chatboxOutline,
} from "ionicons/icons";
import SectionHeading from "../SectionHeading/SectionHeading";

const CoolFeaturesSection: React.FC = () => (
  <div className="h-full w-full my-12 mx-0">
    <SectionHeading className="text-center" heading="MORE COOL FEATURES" />
    <IonGrid>
      <IonRow>
        <IonCol size="12" size-md="3">
          <FeatureCard
            icon={pencilOutline}
            title="Unlimited Practice Sessions"
            description="Enable your students to practice any topic any number of times and watch them succeed."
          />
        </IonCol>
        <IonCol size="12" size-md="3">
          <FeatureCard
            icon={peopleOutline}
            title="Study Groups"
            description={[
              "Wordline lets you connect & share any study material on the click of a button.",
              "Engage with your students in their most important journey towards a successful career.",
            ]}
          />
        </IonCol>
        <IonCol size="12" size-md="3">
          <FeatureCard
            icon={pieChartOutline}
            title="Analytics"
            description="Choosing what to study and identifying all the weak areas on a regular basis is crucial when preparing for tough exams. Wordline will help your students make all the right choices."
          />
        </IonCol>
        <IonCol size="12" size-md="3">
          <FeatureCard
            icon={chatboxOutline}
            title="Feedback"
            description="Learn how your institution is growing and what your students are saying. Bring in change and conquer every challenge."
          />
        </IonCol>
      </IonRow>
    </IonGrid>
  </div>
);

export default CoolFeaturesSection;
