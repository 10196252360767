import React, { useState } from "react";
import "./NavBar.css";
import { Link } from "react-router-dom";
import Logo from "../Logo/Logo";

const HomeNavBar: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const redirectTo = (path: string) => {
    window.location.href = path;
  };

  return (
    <>
      <div className="hidden md:flex gap-[20px] justify-end ">
        <button
          className="nav-link text-white"
          onClick={() => redirectTo("/ui/OpenDashboard")}
        >
          <i className="fa fa-globe" aria-hidden="true"></i> Visit our
          community!
        </button>
        <button
          className="nav-link text-white"
          onClick={() => redirectTo("/register")}
        >
          <i className="fa fa-sign-in" aria-hidden="true"></i> Create your
          profile
        </button>
        <button
          className="nav-link text-white"
          onClick={() => redirectTo("/login")}
        >
          <i className="fa fa-lock" aria-hidden="true"></i> Already have an
          account?
        </button>
      </div>

      <nav className="bg-black bg-opacity-80 text-white p-4 block md:hidden w-full">
        <div className="flex justify-between items-center w-full">
          <Logo />
          <div className="md:hidden">
            <button
              onClick={toggleMenu}
              className="text-white focus:outline-none"
            >
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h16"
                ></path>
              </svg>
            </button>
          </div>
          <div className="hidden md:flex justify-center items-center space-x-4 w-full">
            <a href="#" className="hover:underline">
              Visit our community!
            </a>
            <a href="#" className="hover:underline">
              Create your profile
            </a>
            <a href="#" className="hover:underline">
              Already have an account?
            </a>
          </div>
        </div>

        <div
          className={`md:hidden transition-all duration-700 ease-in-out ${
            isOpen
              ? "max-h-40 opacity-100 transform translate-y-0"
              : "max-h-0 opacity-0 transform -translate-y-10"
          } overflow-hidden flex flex-col items-start pl-4 gap-8`}
        >
          <button
            className="nav-link text-white"
            onClick={() => redirectTo("/ui/OpenDashboard")}
          >
            <i className="fa fa-globe" aria-hidden="true"></i> Visit our
            community!
          </button>
          <button
            className="nav-link text-white"
            onClick={() => redirectTo("/register")}
          >
            <i className="fa fa-sign-in" aria-hidden="true"></i> Create your
            profile
          </button>
          <button
            className="nav-link text-white"
            onClick={() => redirectTo("/login")}
          >
            <i className="fa fa-lock" aria-hidden="true"></i> Already have an
            account?
          </button>
        </div>
      </nav>
    </>
  );
};

export default HomeNavBar;
