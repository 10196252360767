import React from "react";
import { IonGrid, IonRow, IonCol } from "@ionic/react";
import ImageCard from "./ImageCard";
import TextSection from "./TextSection";

const EducationalFeaturesSection: React.FC = () => (
  <div className="px-6">
    <IonGrid className="lg:mt-[-10%]">
      <IonRow class="my-12">
        <IonCol size="12" size-md="6">
          <TextSection
            title="EXPERIENCE LEARNING & TECHNOLOGY IN UNREAL HARMONY"
            content={`Our practice questions are carefully written and edited to give you the most accurate practice possible.\n
                     Study online wherever you go. Our clean and simple interface gets out of the way and lets you learn.\n
                     We bring you closer to the best in-class institutions in your area that select carefully written and edited\n
                     questions to give you the most accurate practice possible.\n
                     Practice exercises and answer explanations! Learn how to deal with each type of questions and practice\n
                     answering them in pace.`}
          />
        </IonCol>
        <IonCol size="12" size-md="6">
          <ImageCard
            src={process.env.PUBLIC_URL + "/assets/img/key_icon.jpg"}
          />
        </IonCol>
      </IonRow>
      <IonRow class="my-12">
        <IonCol size="12" size-md="6">
          <ImageCard
            src={process.env.PUBLIC_URL + "/assets/img/education.jpg"}
            content="Education is the most powerful weapon we can use to change the world. - Nelson Mandela"
          />
        </IonCol>
        <IonCol size="12" size-md="6">
          <TextSection
            title="KNOWLEDGE"
            content={`Wordline is the best way to organize the knowledge so that you can retain and more importantly recall it quickly when required.\n
                     Wordline greatly simplifies your learning process and prepares you for an all-rounded performance on the day of your most important exams.`}
          />
        </IonCol>
      </IonRow>
      <IonRow class="my-12">
        <IonCol size="12" size-md="6">
          <TextSection
            title="LEARNING TOGETHER"
            content={`Find inclined study-mates in our excellent StudyGroups.\n
                     Wordline lets you evaluate your progress through deep analytics and time-tracking.\n
                     Wordline works along with you in your journey of exploring knowledge.`}
          />
        </IonCol>
        <IonCol size="12" size-md="6">
          <ImageCard
            src={process.env.PUBLIC_URL + "/assets/img/elearning-icon.jpg"}
            title="KEEP EDUCATING YOURSELF"
          />
        </IonCol>
      </IonRow>
    </IonGrid>
  </div>
);

export default EducationalFeaturesSection;
